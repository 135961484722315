import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { scrollToPage } from '../../utils/pdfRelatedFunctions';

const OptionDropDownMenu = ({ showClear, handleClearButton, handleDownloadButton }) => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const styles = {
    paper: { borderRadius: '8px' }
  };

  const handleClearData = () => {
    handleClose();
    handleClearButton();
    scrollToPage(1, false);
  };

  const handleDownload = () => {
    handleClose();
    handleDownloadButton();
  };

  return (
    <div className="options" style={{ zIndex: '11' }}>
      <div
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{ cursor: 'pointer' }}
        className="theme-text-primary">
        <span className="dropdown-helper-text mr-2">Options</span>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'right top',
              caretColor: 'yellow'
            }}>
            <Paper elevation={3} style={styles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}>
                  <MenuItem className="border-bottom border-secondary" key={1} onClick={handleDownload}>
                    Download PDF
                  </MenuItem>
                  {showClear && (
                    <MenuItem key={2} onClick={handleClearData}>
                      Clear Data
                    </MenuItem>
                  )}
                  {/* <MenuItem key={3}>Report Abuse</MenuItem> */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

OptionDropDownMenu.propTypes = {
  showClear: PropTypes.bool,
  handleClearButton: PropTypes.func,
  handleDownloadButton: PropTypes.func
};

export default OptionDropDownMenu;

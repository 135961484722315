import React, { Fragment } from 'react';
import { Toaster } from 'react-hot-toast';

const ToastNotification = () => {
  return (
    <Fragment>
      <Toaster toastOptions={{ duration: 5000 }} />
    </Fragment>
  );
};

export default ToastNotification;

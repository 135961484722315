import PropTypes from 'prop-types';
import React from 'react';

const DocumentStatus = ({ status }) => {
  return (
    <div className="document-status">
      {status === 'completed' ? (
        <div className="status-completed">
          <span>Signed</span>
        </div>
      ) : status === 'withdrawn' ? (
        <div className="status-withdrawn">
          <span>Withdrawn</span>
        </div>
      ) : status === 'rejected' ? (
        <div className="status-rejected">
          <span>Rejected</span>
        </div>
      ) : status === 'expired' ? (
        <div className="status-expired">
          <span>Expired</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
DocumentStatus.propTypes = {
  status: PropTypes.string
};

export default DocumentStatus;

import { faKey } from '@fortawesome/free-solid-svg-icons';
import { LoadingButton } from '@mui/lab';
import { Box, Modal } from '@mui/material';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import FormTextField from '../../components/form-fields/FormTextField';
import { AUTH_ANONYMOUS_SUCCESS, AUTH_FAILED, DOCUMENT_CLEAR } from '../../redux/action-types';
import {
  getDocumentBasic,
  requestDocumentAccessCode,
  verifyDocumentAccess
} from '../../redux/document/document-action';
import { validateAccessCodeSchema } from '../../schemas/validateAccessCodeSchema';
import Loader from '../../components/app-components/Loader';

const AccessCodeValidation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const q = query.get('q');
  const RESEND_CODE_TIME_IN_MINUTES = 3;

  const authState = useSelector((state) => {
    return state.auth;
  });

  const timeout = useRef(null);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [requestDisabled, setRequestDisabled] = useState(true);
  const [codeRequestSent, setCodeRequestSent] = useState(false);
  const [isEmailUser, setIsEmailUser] = useState(false);

  async function initiateNewAccess(cId) {
    dispatch({ type: AUTH_FAILED });
    dispatch({ type: DOCUMENT_CLEAR });
    setSectionLoading(true);
    const response = await getDocumentBasic(cId);
    if (response.status === 200) {
      setSubmitDisabled(false);
      setRequestDisabled(false);
      setSectionLoading(false);
      const { isEmail, documentId, receiver } = response.data;
      setFieldValue('receiver', receiver);
      setFieldValue('documentId', documentId);
      setIsEmailUser(isEmail);
    } else {
      setSectionLoading(false);
      navigate(`/`, { replace: true });
    }
  }

  useEffect(() => {
    if (!q) {
      dispatch({ type: AUTH_FAILED });
      dispatch({ type: DOCUMENT_CLEAR });
      navigate(`/`, { replace: true });
    } else {
      if (
        authState.isLogin &&
        authState.anonymousToken &&
        authState.userData?.subId &&
        q.includes(authState.userData.subId)
      ) {
        navigate(`/documents/view/${authState.userData.documentId}`, { replace: true });
      } else {
        initiateNewAccess(q);
      }
    }
  }, []);

  useEffect(() => {
    if (
      authState.isLogin &&
      authState.anonymousToken &&
      authState.userData?.subId &&
      q.includes(authState.userData.subId)
    ) {
      navigate(`/documents/view/${authState.userData.documentId}`, { replace: true });
    }
  }, [authState.isLogin]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: { documentId: '', receiver: '', accessCode: '' },
    validationSchema: validateAccessCodeSchema,
    onSubmit: async (values, { resetForm }) => {
      setSubmitLoading(true);
      await onSubmitHandler(values);
      resetForm({ values: { ...values, accessCode: '' } });
      setSubmitLoading(false);
    }
  });

  const onSubmitHandler = async (values) => {
    try {
      const response = await verifyDocumentAccess(values);
      if (response.status === 200) {
        const { anonymousToken, refreshAnonymousToken, receiver, documentId, subId } = response.data;
        dispatch({
          type: AUTH_ANONYMOUS_SUCCESS,
          payload: {
            anonymousToken,
            refreshAnonymousToken,
            userData: { receiver, documentId, subId }
          }
        });
      } else {
        dispatch({ type: AUTH_FAILED });
      }
    } catch (err) {
      dispatch({ type: AUTH_FAILED });
    }
  };

  const requestAccessCodeHandler = async () => {
    try {
      setRequestLoading(true);
      const payload = { receiver: values.receiver, documentId: values.documentId };
      const response = await requestDocumentAccessCode(payload);
      setRequestLoading(false);
      if (response.status === 200) {
        const message = 'Access code has been sent to your email';
        toast.success(message, { duration: 5000 });
        setRequestDisabled(true);
        setCodeRequestSent(true);
        timeout.current = setTimeout(() => {
          setRequestDisabled(false);
        }, RESEND_CODE_TIME_IN_MINUTES * 60 * 1000);
      }
    } catch (err) {
      setRequestLoading(false);
      const message = 'Something went wrong';
      toast.error(message);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    minWidth: '280px',
    maxWidth: '500px',
    minHeight: '200px',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '0px',
    borderRadius: '1rem',
    boxShadow: 24,
    p: 4
  };

  return (
    <Fragment>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onSubmit={handleSubmit}>
        <Box sx={style}>
          <form autoComplete="off" noValidate>
            {sectionLoading ? (
              <Fragment>
                <Loader color="primary" />
              </Fragment>
            ) : (
              <Fragment>
                <div className="validation-modal-header">
                  <img src={logo} />
                  <h2 className="form-heading">eZy Sign</h2>

                  {isEmailUser ? (
                    <Fragment>
                      <p className="form-sub-heading">
                        {codeRequestSent
                          ? 'We have sent you access code to your email:'
                          : 'We will send you access code on email:'}
                        {`  ${values.receiver.slice(0, 3)}****${values.receiver.slice(-3)}`}
                      </p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <p className="form-sub-heading">
                        We have sent you access code to your phone number:
                        {`  ${values.receiver.slice(0, 3)}****${values.receiver.slice(-3)}`}
                      </p>
                    </Fragment>
                  )}
                </div>

                {(!isEmailUser || codeRequestSent) && (
                  <Fragment>
                    <FormTextField
                      data={{
                        id: 'AccessCodeField',
                        name: 'accessCode',
                        label: 'Access Code',
                        placeholder: 'Enter access code here',
                        required: true,
                        value: values.accessCode,
                        error: errors.accessCode,
                        touched: touched.accessCode,
                        handleChange: handleChange,
                        icon: faKey
                      }}
                    />
                    <LoadingButton
                      className="formButton"
                      type="submit"
                      endIcon={<></>}
                      loading={submitLoading}
                      loadingPosition="end"
                      variant="contained"
                      disabled={submitDisabled}
                      fullWidth>
                      <span className="formButtonText">Submit</span>
                    </LoadingButton>
                  </Fragment>
                )}
                {requestDisabled && (
                  <p className="text-center text-muted mb-0 mt-3">
                    <small>
                      Didn&apos;t receive a code. You can resend code request after {RESEND_CODE_TIME_IN_MINUTES}{' '}
                      minutes
                    </small>
                  </p>
                )}
                {isEmailUser && (
                  <LoadingButton
                    className={`formButton ${codeRequestSent && 'mt-3'}`}
                    endIcon={<></>}
                    loading={requestLoading}
                    disabled={requestDisabled}
                    loadingPosition="end"
                    variant="contained"
                    onClick={requestAccessCodeHandler}
                    fullWidth>
                    <span className="formButtonText">Request access code</span>
                  </LoadingButton>
                )}
                {!requestDisabled && !codeRequestSent && (
                  <p className="text-center mb-0 mt-3">
                    <small>
                      Already have a code,{' '}
                      <span
                        className="theme-text-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setCodeRequestSent(true)}>
                        Click here
                      </span>
                    </small>
                  </p>
                )}
                {/* <FormTextField
              data={{
                id: 'Receiver',
                name: 'receiver',
                label: 'Receiver',
                required: true,
                value: values.receiver,
                error: errors.receiver,
                touched: touched.receiver,
                disabled: true,
                icon: faEnvelope
              }}
            /> */}
              </Fragment>
            )}
          </form>
        </Box>
      </Modal>
    </Fragment>
  );
};

AccessCodeValidation.propTypes = {
  handleClose: PropTypes.func
};

export default AccessCodeValidation;

import toast from 'react-hot-toast';
import { refreshVerifyDocumentAccess } from './document/document-action';
import { AUTH_ANONYMOUS_SUCCESS, AUTH_FAILED } from './action-types';

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { anonymousToken }
      } = store.getState();

      // if (authToken) {
      //   config.headers.Authorization = `Bearer ${authToken}`;
      // }

      if (anonymousToken) {
        config.headers.Authorization = `Bearer ${anonymousToken}`;
      }

      config.timeout = 30000;

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      console.log('error', error);
      if (error.code === 'ERR_NETWORK' || error.code === 'ECONNABORTED') {
        toast.error('Please check your internet connection and try again.', { duration: 10000 });
        return { error: 'network-error' };
      } else {
        const message = error?.response?.data?.message ?? 'Something went wrong';
        const ignoredToastMessages = ['token-expired', 'session-expired'];
        if (!ignoredToastMessages.includes(message)) toast.error(message);
        if (
          error.response?.status === 400 ||
          error.response?.status === 403 ||
          error.response?.status === 404 ||
          error.response?.status === 405 ||
          error.response?.status === 500
        ) {
          return {
            ...error?.response
          };
          // } else if (error.response?.status === 403) {
          // // only redirect to sessionExpired page without change in state
          // window.location.href = '/';
        } else if (error.response?.status === 401) {
          if (error.response.data?.message === 'token-expired') {
            const { auth } = store.getState();

            const refreshResponse = await refreshVerifyDocumentAccess({ refreshToken: auth.refreshAnonymousToken });
            if (refreshResponse.status === 200) {
              const { anonymousToken, refreshAnonymousToken, receiver, documentId, subId } = refreshResponse.data;
              store.dispatch({
                type: AUTH_ANONYMOUS_SUCCESS,
                payload: { anonymousToken, refreshAnonymousToken, userData: { receiver, documentId, subId } }
              });
              const originalRequest = error.config;
              return axios(originalRequest);
            } else {
              store.dispatch({ type: AUTH_FAILED });
            }
          } else {
            window.location.href = '/logout';
          }
          // const originalRequest = error.config;
          // //If ID TOKEN Expired we need to refresh it.
          // // await getRefreshToken()
          // let authToken = await getIdToken();
          // store.dispatch({
          //   type: REFRESH_AUTH_TOKEN,
          //   payload: {
          //     authToken
          //   }
          // });
          // return axios(originalRequest);
          // window.location.href = '/logout'
        }
        return error;
      }
    }
  );
}

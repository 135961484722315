import { MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const FontSelection = ({ font, setFont }) => {
  return (
    <Select
      labelId="demo-multiple-name-label"
      id="demo-multiple-name"
      value={font}
      onChange={(e) => setFont(e.target.value)}
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        height: 40
      }}>
      {fontList.map((fontListItem, i) => (
        <MenuItem key={i} value={fontListItem}>
          {fontListItem}
        </MenuItem>
      ))}
    </Select>
  );
};

export const fontList = [
  'American Typewriter',
  'Andalé Mono',
  'Arial',
  'Arial Black',
  'Bradley Hand',
  'Brush Script MT',
  'Courier',
  'Courier New',
  'Comic Sans MS',
  'Didot',
  'Georgia',
  'Helvetica',
  'Impact',
  'Lucida Console',
  'Luminari',
  'Monaco',
  'Sans-serif',
  'Verdana',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS'
];

FontSelection.propTypes = {
  font: PropTypes.string,
  setFont: PropTypes.func
};

export default FontSelection;

export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ANONYMOUS_SUCCESS = 'AUTH_ANONYMOUS_SUCCESS';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_FAILED = 'AUTH_FAILED';

export const DOCUMENT_LOADING = 'DOCUMENT_LOADING';
export const GET_DOCUMENT_LIST_SUCCESS = 'GET_DOCUMENT_LIST_SUCCESS';
export const GET_DOCUMENT_LIST_FAIL = 'GET_DOCUMENT_LIST_FAIL';
export const GET_DOCUMENT_DETAIL_SUCCESS = 'GET_DOCUMENT_DETAIL_SUCCESS';
export const GET_DOCUMENT_DETAIL_FAIL = 'GET_DOCUMENT_DETAIL_FAIL';
export const DOCUMENT_CLEAR = 'DOCUMENT_CLEAR';

import axios from 'axios';

// eslint-disable-next-line no-undef
const APIURL = process.env.REACT_APP_API_URL;

export const getDocumentFileUploadUrl = async (payload) => {
  try {
    const response = await axios.post(`${APIURL}/files/file-upload-url`, payload);
    if (response.status === 200) {
      return response?.data;
    } else {
      return null;
    }
  } catch (err) {
    throw Error('timed-out');
  }
};

export const getAttachmentsUploadUrls = async (payload) => {
  try {
    const response = await axios.post(`${APIURL}/files/multiple-file-upload-url`, payload);
    if (response.status === 200) {
      return response?.data;
    } else {
      return null;
    }
  } catch (err) {
    throw Error('timed-out');
  }
};

export const uploadFileToCloud = async (url, file) => {
  try {
    const response = await fetch(url, { method: 'PUT', body: file });
    if (response.status === 201 || response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    throw Error('timed-out');
  }
};

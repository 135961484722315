/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import logo from '../../assets/images/logo-white.svg';
import menuActive from '../../assets/images/menu-active.svg';
import menuInactive from '../../assets/images/menu-inactive.svg';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Chip, IconButton, LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import DocumentStatus from '../app-components/DocumentStatus';
import { scrollToPage } from '../../utils/pdfRelatedFunctions';
import { truncateStringEnd, truncateStringMiddle } from '../../utils/utilityFunctions';
import toast from 'react-hot-toast';

const drawerWidth = 300;
const DocumentHeader = ({
  formFields,
  doneDisabled,
  handleDoneButton,
  handleClearButton,
  handleDownloadButton,
  modifiedAttachmentArray,
  setModifiedAttachmentArray,
  setTextModalState,
  setOpenSignature,
  setSignatureSeletedField,
  filledFields,
  fieldsNameMap,

  annotFieldsStatusArray,
  setCurrentPage
}) => {
  const [remainingTime, setRemainingTime] = useState('--:--');
  const [open, setOpen] = useState(false);
  let { documentName, expiryDate, documentStatus, attachmentArray } = useSelector(
    (state) => ({
      documentName: state?.document?.document_detail?.name,
      documentStatus: state?.document?.document_detail?.status,
      attachmentArray: state?.document?.document_detail?.attachments || [],
      expiryDate: state?.document?.document_detail?.expiryDate
    }),
    shallowEqual
  );

  // Attachments
  const fileInputRefs = attachmentArray.map(() => useRef(null));
  const [truncatedFileNameArray, setTruncatedFileNameArray] = useState(
    Array.from({ length: attachmentArray.length }, () => ({
      selectedFileName: ''
    }))
  );
  const [percentage, setPercentage] = useState(0);

  const filledFieldsLength_ref = useRef(0);
  useEffect(() => {
    let filledFieldsLength = 0;
    if (annotFieldsStatusArray.length > 0) {
      annotFieldsStatusArray.forEach((elem) => {
        if (elem?.isUpdated) {
          filledFieldsLength++;
        }
      });
    }
    if (filledFieldsLength > 0) filledFieldsLength_ref.current = filledFieldsLength;
  }, [annotFieldsStatusArray]);

  const handleFieldClick = (pageNumber) => {
    setOpen(false);
    setCurrentPage(pageNumber);
    scrollToPage(pageNumber, false);
  };

  function getExpiryTime(expiryTimestamp) {
    const millisecondsInHour = 1000 * 60 * 60;
    const millisecondsInDay = millisecondsInHour * 24;
    const millisecondsInMonth = millisecondsInDay * 30;
    const millisecondsInYear = millisecondsInDay * 365;

    const customDate = new Date();
    customDate.setFullYear(2023); // Set the year (e.g., 2023)
    customDate.setMonth(6); // Set the month (January is 0, so 6 is July)
    customDate.setDate(31); // Set the day of the month (1-31)
    customDate.setHours(14); // Set the hours (24-hour format)

    const expiryDate = new Date(expiryTimestamp);
    // const expiryDate = new Date(customDate);
    const currentDate = new Date();

    let timeDifference = expiryDate - currentDate;
    // let timeDifference = expiryDate;
    const years = Math.floor(timeDifference / millisecondsInYear);
    timeDifference %= millisecondsInYear;

    const months = Math.floor(timeDifference / millisecondsInMonth);
    timeDifference %= millisecondsInMonth;

    const days = Math.floor(timeDifference / millisecondsInDay);
    timeDifference %= millisecondsInDay;

    const hours = Math.floor(timeDifference / millisecondsInHour);
    timeDifference %= millisecondsInDay;

    if (timeDifference > 0) {
      if (years >= 1) {
        return `${years} year${years > 1 ? 's' : ''}, ${months} month${months > 1 ? 's' : ''}`;
      } else if (months >= 1) {
        return `${months} month${months > 1 ? 's' : ''}, ${days} day${days > 1 ? 's' : ''}`;
      } else if (days >= 1) {
        return `${days} day${days > 1 ? 's' : ''}, ${hours} hour${hours > 1 ? 's' : ''}`;
      } else {
        return `${hours} hour${hours > 1 ? 's' : ''}`;
      }
    } else {
      return 'expired';
    }
  }

  useEffect(() => {
    if (expiryDate) {
      const date = getExpiryTime(expiryDate);
      setRemainingTime(date);
    }
  }, [expiryDate]);

  const handleInputDivClick = (index) => {
    // Trigger the click event on the hidden file input
    fileInputRefs[index].current.click();
  };

  const handleTextFieldClick = (myValue) => {
    setOpen(false);
    setTextModalState({
      open: true,
      fieldName: myValue,
      fieldValue: ''
    });
  };

  const isObjectEmpty = (obj) => {
    for (let z in obj) {
      return false;
    }
    return true;
  };

  const handleButtonFieldClick = (myValue) => {
    setOpen(false);
    setOpenSignature(true);
    setSignatureSeletedField(myValue);
  };

  useEffect(() => {
    // resetting truncatedFileNameArray if there is no attachment in modifiedAttachmentArray
    const isAnyAttachmentAttached = () => {
      let isAnySelectedFileDetailNotEmpty = false;
      if (modifiedAttachmentArray?.length > 0) {
        const isObjectEmpty = (obj) => {
          for (let z in obj) {
            return false;
          }
          return true;
        };
        // Check if any object in the "modifiedAttachmentArray" array has a non-empty "selectedFileDetail" attribute.
        isAnySelectedFileDetailNotEmpty = modifiedAttachmentArray.some((item) => {
          return !isObjectEmpty(item.selectedFileDetail);
        });
      }
      return isAnySelectedFileDetailNotEmpty;
    };

    if (!isAnyAttachmentAttached()) {
      setTruncatedFileNameArray(
        Array.from({ length: attachmentArray.length }, () => ({
          selectedFileName: ''
        }))
      );
    }
    setPercentage(
      (modifiedAttachmentArray.filter((a) => !isObjectEmpty(a.selectedFileDetail)).length /
        modifiedAttachmentArray.length) *
        100
    );
  }, [modifiedAttachmentArray]);

  const handleFileChange = (event) => {
    const file = event?.target?.files[0]; // Get the selected file
    const attachIndex = event?.target?.id;
    const maxFileSizeInBytes = 5 * 1024 * 1024; // for 5mb
    if (file && file.size <= maxFileSizeInBytes) {
      const newModifiedAttachmentArray = [...modifiedAttachmentArray];
      newModifiedAttachmentArray[attachIndex].selectedFileDetail = file;
      setModifiedAttachmentArray(newModifiedAttachmentArray);
    } else {
      toast.error('File size exceeds the limit of 5 MB.');
      // unattach the file if already attach, when new attachment is greater than maxFileSize
      const newModifiedAttachmentArray = [...modifiedAttachmentArray];
      newModifiedAttachmentArray[attachIndex].selectedFileDetail = {};
      setModifiedAttachmentArray(newModifiedAttachmentArray);
      event.target.value = null;
    }
  };

  return (
    <div className="document-head-mv">
      <Box
        className="document-header-sm d-flex justify-content-between align-items-center px-3"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: 'none' }}>
        <IconButton
          className="p-0"
          onClick={() => {
            setOpen(!open);
          }}>
          <img src={open ? menuActive : menuInactive} />
        </IconButton>
        <div className='col d-flex flex-column justify-content-center navbarTitleDiv'>
          {/* <div className="required-text">{truncateStringEnd(documentName, 10) || 'Your Document'}</div> */}
          <div className="required-text">{documentName || 'Your Document'}</div>
          <div className="expiry">Expire in : {remainingTime}</div>
        </div>

        {documentStatus === 'pending' && expiryDate > Date.now() ? (
          <button className="header-button" disabled={doneDisabled} onClick={() => {
            setOpen(false);
            handleDoneButton();
            }}>
            <div className='d-flex align-items-center'>
              <span className=''>Done</span>
              { doneDisabled ? 
                <div className="spinner-border text-light ml-2" style={{width: '1.2rem', height: '1.2rem'}} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                : null
              }
            </div>
          </button>
        ) : (
          <div></div>
        )}
        {documentStatus !== 'pending' && <DocumentStatus status={documentStatus} />}
        {documentStatus === 'pending' && expiryDate < Date.now() && <DocumentStatus status={documentStatus} />}
      </Box>
      <Drawer
        className="drawer"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        open={open}
        onClose={() => setOpen(false)}>
        <Toolbar sx={{ minHeight: '54px!important'}}/>
        <Toolbar sx={{ minHeight: '54px!important'}}/>
        <Box sx={{ overflow: 'auto', mt: 0 }}>
          <List>
            <ListItem disablePadding dense>
              <ListItemButton
                onClick={() => {
                  setOpen(false);
                  handleDownloadButton();
                }}>
                <ListItemText primary={'Download PDF'} />
              </ListItemButton>
            </ListItem>
            {documentStatus === 'pending' && expiryDate > Date.now() && (
              <ListItem
                disablePadding
                dense
                // sx={{ paddingTop: 0, paddingBottom: 0, margin: 0, lineHeight: '10px !important' }}
                // sx={{ height: '50px'}}
              >
                <ListItemButton
                  onClick={() => {
                    setOpen(false);
                    handleClearButton();
                  }}>
                  <ListItemText primary={'Clear Data'} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
          {documentStatus === 'pending' && expiryDate > Date.now() && (
            <>
              <Divider />
              <p>Required Fields</p>
              <List>
                <div className="mx-3">
                  <div className="status-text">
                    You need to complete <strong>{annotFieldsStatusArray?.length}</strong> required fields
                  </div>
                  <LinearProgress
                    sx={{ mb: 1 }}
                    variant="determinate"
                    value={(filledFieldsLength_ref.current / annotFieldsStatusArray?.length) * 100}
                  />
                </div>

                {annotFieldsStatusArray.map((formField, i) => (
                  <ListItem
                    key={i}
                    disablePadding
                    dense
                    // divider
                    // divider={i < annotFieldsStatusArray?.length - 1 ? true : false}
                    onClick={() => handleFieldClick(formField?.pageNumber)}
                    // className={'border-bottom border-secondary'}
                    // className={'fieldsListItemStyle'}
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={
                          <>
                            {formField?.fieldName ? truncateStringEnd(formField?.fieldName, 14) : 'Field name'}
                            {formField?.pageNumber > 0 ? (
                              <span className="customBadge">{`Page: ${formField?.pageNumber}`}</span>
                            ) : null}
                          </>
                        }
                        style={{
                          width: 'fitContent',
                          flexGrow: '0',
                          marginTop: '0',
                          marginBottom: '0'
                        }}
                      />
                      {/* {formField?.pageNumber ?
                          <Chip
                            label={`pageNo: ${formField?.pageNumber}`}
                            variant="outlined"
                            size="small"
                            style={{
                              marginLeft: '0.5rem',
                              borderRadius: '50px',
                              // padding: '0.5rem 0.2rem',
                              cursor: 'pointer',
                              fontSize: '0.6rem',
                              background: '#cdff00'
                            }}
                          />
                        : null} */}

                      {formField?.isUpdated ? (
                        <span className="ml-auto">
                          <FontAwesomeIcon icon={faCheck} className="theme-text-primary" />
                        </span>
                      ) : null}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Divider />
              {attachmentArray.length > 0 && (
                <>
                  <p>Attachments</p>
                  <List>
                    <div className="mx-3">
                      <div className="status-text">
                        You need to attach <strong>{attachmentArray.length}</strong> files
                        <span className="fileSizeInfo">File size &lt; 5MB</span>
                      </div>
                      <LinearProgress variant="determinate" value={percentage} sx={{ mb: 1 }} />
                    </div>
                    {attachmentArray.map((attachment, i) => (
                      <ListItem
                        key={i}
                        disablePadding
                        dense>
                        <input
                          type="file"
                          id={i}
                          style={{ display: 'none' }}
                          data-my-value={JSON.stringify(attachment)}
                          accept=".pdf,.bmp,.png,.jpg,.jpeg"
                          ref={fileInputRefs[i]}
                          onChange={(event) => handleFileChange(event)}
                        />
                        <ListItemButton
                          onClick={() => handleInputDivClick(i)}>
                          <ListItemText
                            style={{
                              marginTop: '0',
                              marginBottom: '0'
                            }}
                            primary={
                              <>
                                <div className="d-flex align-items-center justify-content-between">
                                  {/* {
                                        modifiedAttachmentArray &&
                                        modifiedAttachmentArray[i] &&
                                        modifiedAttachmentArray[i]?.selectedFileDetail?.name ? (
                                          <span className="mr-2 text-success" style={{ fontSize: '12px' }}>
                                            {truncateStringMiddle(modifiedAttachmentArray[i]?.selectedFileDetail?.name, 14)}
                                          </span>
                                        ) : (
                                          <span className="mr-2 text-danger" style={{ fontSize: '12px' }}>
                                            {'No file chosen'}
                                          </span>
                                      )} */}

                                  <div className="flex-start mr-1 list-item-text">
                                    {attachment?.label ? truncateStringEnd(attachment?.label, 15) : 'Attachment Name'}
                                    {!attachment.optional ? <span className="text-danger">&nbsp;∗</span> : null}
                                  </div>

                                  <div
                                    className="btn theme-button-attachment"
                                    style={{
                                      background:
                                        modifiedAttachmentArray &&
                                        modifiedAttachmentArray[i] &&
                                        modifiedAttachmentArray[i]?.selectedFileDetail?.name
                                          ? '#008B00'
                                          : '#838383'
                                    }}>
                                    <label
                                      className="mb-0"
                                      style={{
                                        cursor: 'pointer'
                                      }}>
                                      {
                                        modifiedAttachmentArray &&
                                        modifiedAttachmentArray[i] &&
                                        modifiedAttachmentArray[i]?.selectedFileDetail?.name 
                                        ? `Edit`
                                        : 'Attach'
                                      }
                                      
                                    </label>
                                    <FontAwesomeIcon icon={faPaperclip} className="ml-2" />
                                  </div>
                                </div>
                              </>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </>
          )}
        </Box>
      </Drawer>
    </div>
  );
};

DocumentHeader.propTypes = {
  fieldsNameMap: PropTypes.object,
  formFields: PropTypes.array,
  doneDisabled: PropTypes.bool,
  handleDoneButton: PropTypes.func,
  handleClearButton: PropTypes.func,
  handleDownloadButton: PropTypes.func,
  modifiedAttachmentArray: PropTypes.array,
  setModifiedAttachmentArray: PropTypes.func,
  setTextModalState: PropTypes.func,
  setOpenSignature: PropTypes.func,
  setSignatureSeletedField: PropTypes.func,
  filledFields: PropTypes.array,
  annotFieldsStatusArray: PropTypes.array,
  setCurrentPage: PropTypes.func
};

export default DocumentHeader;

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_LOADING, DOCUMENT_CLEAR } from '../action-types';
import * as requestFromServer from './auth-crud';

export const logout = () => async (dispatch) => {
  dispatch({ type: AUTH_LOGOUT });
  dispatch({ type: DOCUMENT_CLEAR });
};

export const authLoading = (props) => {
  return {
    type: AUTH_LOADING,
    payload: props
  };
};

export const login = async (data, dispatch) => {
  let responseStatus = false;
  try {
    let response = await requestFromServer.login(data);
    if (response.status === 200) {
      localStorage.setItem('authToken', data.authToken);
      dispatch({
        type: AUTH_LOGIN,
        payload: {
          authToken: data.authToken,
          userData: response.data
        }
      });
      responseStatus = true;
    } else {
      responseStatus = false;
    }
  } catch (error) {
    responseStatus = false;
  }
  return responseStatus;
};

export const signUp = async (data) => {
  let responseStatus = false;
  try {
    let response = await requestFromServer.signUp(data);
    if (response.status === 200) {
      responseStatus = true;
    } else {
      responseStatus = false;
    }
  } catch (error) {
    responseStatus = false;
  }
  return responseStatus;
};

/* eslint-disable */
import { faTextWidth } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Modal } from '@mui/material';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import FormTextField from '../../components/form-fields/FormTextField';
import { validateTextModalSchema } from '../../schemas/validateTextModalSchema';

const EditTextModal = ({ textModalState, setTextModalState, updateFieldData, handleTextFontSize }) => {
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: { text: textModalState.textValue || '' },
    validationSchema: validateTextModalSchema,
    onSubmit: async (values, { resetForm }) => {
      onSubmitHandler(values);
      resetForm({ values: { text: '' } });
    }
  });

  const [fontSize, setFontSize] = useState(textModalState?.fontSize || 16);
  const onSubmitHandler = async (values) => {
    setTextModalState({
      ...textModalState,
      open: false,
      textValue: values.text
    });

    // console.log("dsvfgbntyb ", values, textModalState);

    await updateFieldData({
      id: textModalState.id,
      textValue: values.text
    });
  };

  // console.log("dfgrtbvrcv ",  textModalState, setTextModalState, updateFieldData);

  const handleClose = () => {
    setTextModalState({
      ...textModalState,
      open: false
    });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '500px',
    minHeight: '200px',
    minWidth: '300px',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '0px',
    borderRadius: '1rem',
    boxShadow: 24,
    p: 4
  };

  const handleFontSizeClick = (change)=>{
    const fieldProps = {
      id: textModalState?.id || 0,
      pageNumber: textModalState?.pageNumber || 1
    }
    const fontSizeUpperLimit = 999;
    const fontSizeLowerLimit = 1;
    const newFontSize = fontSize + change;
    if(newFontSize >= fontSizeLowerLimit && newFontSize <= fontSizeUpperLimit){
      setFontSize(newFontSize);
      handleTextFontSize(fieldProps, newFontSize)
    }
  }

  return (
    <Fragment>
      <Modal
        open={textModalState.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onSubmit={handleSubmit}
        onClose={handleClose}>
        <Box sx={style}>
          <form autoComplete="off" noValidate>
            <div className="text-nowrap text-center mb-3">
              {/* <h2 className="formHeading mb-0">{textModalState.fieldName}</h2> */}
              <span className="text-wrap">Enter text below to fill field</span>
            </div>
            <FormTextField
              data={{
                id: 'Text',
                name: 'text',
                label: 'Enter text',
                placeholder: 'Enter text here',
                required: true,
                value: values.text,
                error: errors.text,
                touched: touched.text,
                handleBlur: handleBlur,
                handleChange: handleChange,
                icon: faTextWidth,
                multiline: textModalState?.type==="multiline" ? true : false
              }}
            />
            <div className='d-flex justify-content-between fontDiv'>
              <span>Font Size: </span>
              <div>
                <span>{Math.floor(fontSize)}</span>
                <div className="btn-group" role="group" aria-label="Basic example">
                  <button type="button" className="btn" onClick={()=>handleFontSizeClick(-1)}>-</button>
                  <button type="button" className="btn" onClick={()=>handleFontSizeClick(+1)}>+</button>
                </div>
              </div>
            </div>

            <Button className="formButton" type="submit" variant="contained" fullWidth>
              <span className="formButtonText">Save</span>
            </Button>
          </form>
        </Box>
      </Modal>
    </Fragment>
  );
};

EditTextModal.propTypes = {
  textModalState: PropTypes.object,
  setTextModalState: PropTypes.func,
  updateFieldData: PropTypes.func
};

export default EditTextModal;

import React from 'react';
import notFoundImage from '../../assets/images/not-found.jpg';
const NotFound = () => {
  return <div style={styles}></div>;
};

const styles = {
  backgroundImage: `url(${notFoundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100vw',
  height: '100vh',
  position: 'absolute',
  zIndex: 999
};

export default NotFound;

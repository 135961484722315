import axios from 'axios';
import { DOCUMENT_LOADING, GET_DOCUMENT_DETAIL_FAIL, GET_DOCUMENT_DETAIL_SUCCESS } from '../action-types';

// eslint-disable-next-line no-undef
const APIURL = process.env.REACT_APP_API_URL;

// export const verifyDocumentAccess = (data) => async (dispatch) => {
//   try {
//     dispatch({ type: AUTH_LOADING, payload: true });
//     console.log(data, 123);
//     const response = await axios.post(`${APIURL}${VERIFY_ACCESS_URL}`, data);
//     console.log(response.status, response.data);
//     if (response.status === 200) {
//       const { anonymousToken, email, documentId } = response.data;
//       setTimeout(() => {
//         dispatch({
//           type: AUTH_ANONYMOUS_SUCCESS,
//           payload: {
//             anonymousToken,
//             userData: { email, documentId }
//           }
//         });
//       }, 2000);
//     } else {
//       dispatch({ type: AUTH_FAILED, payload: response.data });
//     }
//   } catch (err) {
//     dispatch({ type: AUTH_FAILED, payload: null });
//   }
// };

export const requestDocumentAccessCode = (data) => {
  return axios.post(`${APIURL}/documents/requestAccess`, data);
};

export const verifyDocumentAccess = (data) => {
  return axios.post(`${APIURL}/documents/verifyAccess`, data);
};

export const refreshVerifyDocumentAccess = (data) => {
  return axios.post(`${APIURL}/documents/refreshVerifyAccess`, data);
};

export const getDocumentBasic = (cId) => {
  return axios.get(`${APIURL}/documents/${cId}/basic`);
};

export const getDocumentStatus = async (id) => {
  try {
    const response = await axios.post(`${APIURL}/documents/${id}/getStatus`);
    if (response?.error === 'network-error') {
      return response;
    } else if (response?.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const getDocumentDetailAnonymous = (id) => {
  return axios.get(`${APIURL}/documents/${id}/anonymous`);
};

export const getDocumentDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: DOCUMENT_LOADING });
    const response = await axios.get(`${APIURL}/documents/${id}/anonymous`);
    if (response.status === 200) {
      dispatch({
        type: GET_DOCUMENT_DETAIL_SUCCESS,
        payload: {
          document_detail: response.data
        }
      });
    } else {
      dispatch({ type: GET_DOCUMENT_DETAIL_FAIL });
    }
  } catch (err) {
    dispatch({ type: GET_DOCUMENT_DETAIL_FAIL });
  }
};

export const replyDocument = async (id, payload) => {
  try {
    const response = await axios.patch(`${APIURL}/documents/${id}/anonymousReply`, payload);
    if (response?.status === 200) {
      return { data: response.data };
    } else {
      return null;
    }
  } catch (err) {
    throw Error('timed-out');
  }
};

/* eslint-disable */
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { scrollToPage } from '../../utils/pdfRelatedFunctions';
import { truncateStringEnd, truncateStringMiddle } from '../../utils/utilityFunctions';
// import { Chip } from '@mui/material';
import Chip from '@mui/material/Chip';

const DropDownMenu = ({
  annotFieldsStatusArray,
  setCurrentPage
}) => {
  // console.log("asdfvrevf ", annotFieldsStatusArray);
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);
  const filledFieldsLength_ref = useRef(0);
  useEffect(() => {
    let filledFieldsLength = 0;
    if (annotFieldsStatusArray.length > 0) {
      annotFieldsStatusArray.forEach((elem, index) => {
        if (elem?.isUpdated) {
          filledFieldsLength++;
        }
      });
    }
    if (filledFieldsLength > 0) filledFieldsLength_ref.current = filledFieldsLength;
  }, [annotFieldsStatusArray]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const styles = {
    paper: { borderRadius: '8px', padding: '2rem 1rem 0 1rem' },
    menuItem: { paddingLeft: 0, paddingRight: 0 }
  };

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToPage(pageNumber, false);
    setOpen(false);
  };

  return (
    <div style={{ zIndex: '12' }}>
      <div
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className="itemBtn btn theme-button-primary">
        {annotFieldsStatusArray?.length}
      </div>
      {anchorRef?.current ? 
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'right top',
                caretColor: 'yellow'
              }}>
              <Paper elevation={3} style={styles.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <h6>You need to complete {annotFieldsStatusArray?.length} required fields</h6>
                    <LinearProgress
                      variant="determinate"
                      value={(filledFieldsLength_ref.current / annotFieldsStatusArray?.length) * 100}
                    />
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}>
                      {annotFieldsStatusArray?.map((formField, i) => (
                        <MenuItem
                          key={i}
                          onClick={() => handleClick(formField?.pageNumber)}
                          data-my-value={formField?.fieldName}
                          style={styles.menuItem}
                          className={i < annotFieldsStatusArray?.length - 1 ? 'border-bottom border-secondary' : ''}>
                          {formField?.fieldName ? truncateStringEnd(formField?.fieldName, 14) : 'Field name'}
                          {formField?.pageNumber > 0 ? (
                              <span className="customBadge">{`Page: ${formField?.pageNumber}`}</span>
                            ) : null
                          }
                          {formField?.isUpdated ? (
                            <span className="ml-auto">
                              <FontAwesomeIcon icon={faCheck} className="theme-text-primary" />
                            </span>
                          ) : null}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      : null}
    </div>
  );
};

DropDownMenu.propTypes = {
  fieldsNameMap: PropTypes.object,
  formFields: PropTypes.array,
  filledFields: PropTypes.array,
  setTextModalState: PropTypes.func,
  setOpenSignature: PropTypes.func,
  setSignatureSeletedField: PropTypes.func,
  annotFieldsStatusArray: PropTypes.array,
  setCurrentPage: PropTypes.func
};

export default DropDownMenu;

import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const Loader = ({ color }) => {
  return (
    <div className="loader">
      <CircularProgress color={color} />
    </div>
  );
};
Loader.propTypes = {
  color: PropTypes.string
};

export default Loader;

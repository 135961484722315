import React from 'react';
import PropTypes from 'prop-types';

const SignatureTextField = ({ signatureText, setSignatureText, font, maxLength }) => {
  const setValidText = (text) => {
    if (text.length <= maxLength) setSignatureText(text);
  };
  return (
    <div className="signature-textfield">
      <div className="top">
        <input
          type="text"
          placeholder="Type here"
          value={signatureText}
          style={{ fontFamily: font }}
          onChange={(e) => setValidText(e.target.value)}
        />
      </div>
      <div className="bottom"></div>
    </div>
  );
};

SignatureTextField.propTypes = {
  font: PropTypes.string,
  signatureText: PropTypes.string,
  setSignatureText: PropTypes.func,
  maxLength: PropTypes.number
};

export default SignatureTextField;

/* eslint-disable */

import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

const pageAssetsPath = '../../assets/indexPage';

// Google Fonts
import '../../assets/indexPage/css';
// Vendor CSS Files
// import "../../assets/indexPage/bootstrap.min.css";
import '../../assets/indexPage/icofont.min.css';
import '../../assets/indexPage/boxicons.min.css';
import '../../assets/indexPage/venobox.css';
import '../../assets/indexPage/aos.css';
// homePage CSS File
import '../../assets/indexPage/style.css';
import '../../assets/indexPage/contactPage.css';
import faqContent from '../../data/faqContent.json';
import featureContent from '../../data/featureContent.json';
import pricingContent from '../../data/pricingContent.json';
import portfolioContent from '../../data/portfolioContent.json';
import ReCAPTCHA from 'react-google-recaptcha'
import heroImage from '../../assets/indexPage/hero-img.png'

import secureIcon from "../../assets/indexPage/secureIcon.svg";
import securityIcon from "../../assets/indexPage/securityIcon.svg";

import jquery from 'jquery';
import "../../assets/indexPage/js/main";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'venobox/dist/venobox.css';
import VenoBox from 'venobox/dist/venobox.js';
import 'jquery.easing';

AOS.init()

const HomePage2 = () => {

  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const recaptcha = useRef();

  const [activeItem, setActiveItem] = useState('hero');
  const [contactStatus, setContactStatus] = useState({message: "", error: false, success: false});

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  useEffect(() => {
    new VenoBox({
      selector: '.venobox'
    });
  }, []);

  // Add scroll event listener when component mounts
  useEffect(() => {

    let timeout;
    clearTimeout(timeout);

    const handleScroll = () => {  
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll('section[id]');

      for(let i=0; i<sections?.length; i++){
        const section = sections[i];
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        // console.log("ijijij ", i, sectionTop, sectionHeight, scrollPosition)
        // if(i==1) break;
        // if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
        if (scrollPosition < (sectionTop + sectionHeight - 5)) {
          const sectionIdInView = section.getAttribute('id').toString();
          // setActiveItem((prev)=>{
          //   if(prev!=sectionIdInView){
          //     return sectionIdInView;
          //   }
          //   else{
          //     return prev;
          //   }
          // });
          setActiveItem(sectionIdInView);
          break;
        }
      }

    };

    window.addEventListener('scroll', ()=>{
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        // Call your function here
        // console.log("scroll stoped");
        handleScroll();
      }, 200);
    });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const getFeatureImages = () => {
    const imagesArray = [];
    const noOfImages = 10;
    for (let i = 1; i <= noOfImages; i++) {
      let image;
        try {
            image = require(`../../assets/indexPage/featureImages/${i}.svg`);
        } catch (error) {
            try {
                image = require(`../../assets/indexPage/featureImages/${i}.png`);
            } catch (error) {
              try {
                  image = require(`../../assets/indexPage/featureImages/${i}.jpg`);
              } catch (error) {
                  console.error(`Neither an SVG, JPG, nor PNG found for image ${i}`);
              }
            }
        }
      imagesArray.push(image);
    }
    return imagesArray;
  };

  const getProductSnapshots = () => {
    const imagesArray = [];
    const noOfImages = 9;
    for (let i = 1; i <= noOfImages; i++) {
      let image;
        try {
            image = require(`../../assets/indexPage/productSnapshots/${i}.svg`);
        } catch (error) {
            try {
                image = require(`../../assets/indexPage/productSnapshots/${i}.png`);
            } catch (error) {
              try {
                  image = require(`../../assets/indexPage/productSnapshots/${i}.jpg`);
              } catch (error) {
                  console.error(`Neither an SVG, JPG, nor PNG found for image ${i}`);
              }
            }
        }
      imagesArray.push(image);
    }
    return imagesArray;
  };

  const initialize_aos_library = (once_value = false) => {
    AOS.init({
      duration: 500,
      easing: 'ease-in-out',
      once: once_value
    });
  };

  // this code fixed the issue of not occuring div's animations on correct position while scrolling.
  useEffect(() => {
    initialize_aos_library(false);
    setTimeout(() => {
      initialize_aos_library(true);
    }, 1500);

    // const resizeObserver = new ResizeObserver(entries => {
    //   for (const entry of entries) {
    //     initialize_aos_library(true);
    //     break;
    //   }
    // });
    // resizeObserver.observe(document.body);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  function clearForm() {
    var form = document.getElementById('contact-form');
    for (var c = 0; c < form.elements.length; c++) {
        var element = form.elements[c];
        if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA' || element.tagName === 'SELECT') {
            element.value = '';
        }
    }
  }

  const validateForm = async ()=>{
    document.getElementById('username').classList.remove('error');
    document.getElementById('email').classList.remove('error');
    document.getElementById('message').classList.remove('error');

    setContactStatus({message: "", error: false, success: false});

    let emailData = document.getElementById('email').value;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = regex.test(emailData);
    if (!isEmailValid) {
      emailData = '';
    }
    let data = {
      name: document.getElementById('username').value,
      email: emailData,
      message: document.getElementById('message').value
    };
    if (data.name && data.email && data.message) {
      return true;      
    } else {
      setContactStatus({message: "Please fill out mandatory fields.", error: true, success: false});
      if (!data.name) document.getElementById('username').classList.add('error');
      if (!data.email) document.getElementById('email').classList.add('error');
      if (!data.message) document.getElementById('message').classList.add('error');
      return false;
    }
  }

  const handleContactSubmitBtn = async (event) => {
    event.preventDefault();
    const isValidate = await validateForm();
    if(isValidate){
      const captchaValue = recaptcha.current.getValue()
      if (!captchaValue) {
        // below code to show error in status div
        setContactStatus({message: "Please verify reCAPTCHA first!", error: true, success: false});
      } else {

        const contactSubmitBtn = document.querySelector('.contactSubmitBtn');
        contactSubmitBtn.disabled = true;
        //enable spinner inside the button element.
        var spinnerElement = document.querySelector('.spinner');
        spinnerElement.style.display = 'inline-block';

        const bodyData = {
          name: document.getElementById('username').value,
          email: document.getElementById('email').value,
          message: document.getElementById('message').value,
          token: captchaValue
        };

        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/contact-us`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          })
          .then((res)=>{
            if(res.status===200){
              clearForm();
              setContactStatus({message: "Message sent successfully.", error: false, success: true});
            }
            else{
              throw new Error("An error occured!");
            }
          })
        } catch (err) {
          //below code to show error
          setContactStatus({message: "Error occured, try again!", error: true, success: false});
        }
        finally{
          spinnerElement.style.display = 'none';
          contactSubmitBtn.disabled = false;
          // reset the recaptcha after the api call
          recaptcha.current.reset();
        }
      }
    }
  };

  return (
    <>
      <div className="indexMain">
        <div id="headerBar">
          <button type="button" className="mobile-nav-toggle d-lg-none">
            <i className="icofont-navigation-menu"></i>
          </button>
          <div className="container d-flex">
            <div className="mr-auto d-flex flex-column">
              <div className="appLogo mr-auto d-flex align-items-center">
                {/* <a href=""> */}
                  <img src={require("../../assets/images/logo.png")} alt="" className="img-fluid mr-2"/>
                {/* </a> */}


                <div>
                  <h1 className="text-light">
                    eZy Sign
                  </h1>
                  {/* <span>Digitally Sign, Scan, Fill PDF</span> */}
                  <span className='text-white' style={{fontSize: "14px"}}>Digitally Sign, Scan, Fill PDF</span>
                </div>
                {/* Uncomment below if you prefer to use an image logo
            <a href="index.html"><img src="assets/img/logo.png" alt="" className="img-fluid"></a> */}
              </div>
              {/* <span className='text-white' style={{fontSize: "14px"}}>Digitally Sign, Scan, Fill PDF</span> */}
            </div>

            <nav className="nav-menu d-none d-lg-block">
              <ul>
                <li className={`${activeItem==="hero" ? "active" : ""}`}>
                  <a href="#hero">Home</a>
                </li>
                <li className={`${activeItem==="about" ? "active" : ""}`}>
                  <a className="nav-link scrollto"  href="#about">
                    About App
                  </a>
                </li>
                <li className={`${activeItem==="features" ? "active" : ""}`}>
                  <a className="nav-link scrollto" href="#features">
                    Features
                  </a>
                </li>
                <li className={`${activeItem==="portfolio" ? "active" : ""}`}>
                  <a className="nav-link scrollto" href="#portfolio">
                    A Quick Glimpse
                  </a>
                </li>
                <li className={`${activeItem==="pricing" ? "active" : ""}`}>
                  <a className="nav-link scrollto" href="#pricing">
                    Pricing
                  </a>
                </li>
                <li className={`${activeItem==="contact" ? "active" : ""}`}>
                  <a className="nav-link scrollto" href="#contact">
                    Contact Us
                  </a>
                </li>

                {/* <li className="get-started">
                  <a className="nav-link scrollto" href="#about">
                    Get Started
                  </a>
                </li> */}
              </ul>
            </nav>

            <nav className="d-lg-none mobile-nav">
              <ul>
                <li className={`${activeItem==="hero" ? "active" : ""}`}>
                  <a href="#hero">Home</a>
                </li>
                <li className={`${activeItem==="about" ? "active" : ""}`}>
                  <a className="nav-link scrollto"  href="#about">
                    About App
                  </a>
                </li>
                <li className={`${activeItem==="features" ? "active" : ""}`}>
                  <a className="nav-link scrollto" href="#features">
                    Features
                  </a>
                </li>
                <li className={`${activeItem==="portfolio" ? "active" : ""}`}>
                  <a className="nav-link scrollto" href="#portfolio">
                    A Quick Glimpse
                  </a>
                </li>
                <li className={`${activeItem==="pricing" ? "active" : ""}`}>
                  <a className="nav-link scrollto" href="#pricing">
                    Pricing
                  </a>
                </li>
                <li className={`${activeItem==="contact" ? "active" : ""}`}>
                  <a className="nav-link scrollto" href="#contact">
                    Contact Us
                  </a>
                </li>

                {/* <li className="get-started">
                  <a className="nav-link scrollto" href="#about">
                    Get Started
                  </a>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>

        <section id="hero">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="heroContent col-lg-6 py-5 py-lg-0 order-2 order-lg-1 aos-init aos-animate" data-aos="fade-right">
                <h1>A complete digital signature platform</h1>
                <h2>
                  Your go-to solution for quick and secure document signatures. Easily send, sign, and manage all your
                  contracts in one place.
                </h2>
                {/* <a
                  href="file:///C:/Users/Lenovo/Downloads/bocor-master/index.html#about"
                  className="btn-get-started scrollto">
                  Get Started
                </a> */}
              </div>
              <div className="col-lg-6 order-1 order-lg-2 hero-img aos-init aos-animate" data-aos="fade-left">
                <img src={heroImage} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>

        <main id="main">
          {/* ======= About Section ======= */}
          <section id="about" className="about section-bg">
            <div className="container">
              <div className="row">
                <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"></div>
                <div className="col-xl-7">
                  <div className="content d-flex flex-column justify-content-center pl-0 pl-xl-4">
                    <h3 data-aos="fade-in" data-aos-delay="100" className="aos-init aos-animate">
                      A comprehensive solution for document management, collaboration, and creation.
                    </h3>
                    <p data-aos="fade-in" className="aos-init aos-animate">
                      Optimize productivity with integrated document management, collaboration, and creation capabilities.
                    </p>
                    <div className="row">
                      <div className="col-md-6 icon-box aos-init aos-animate" data-aos="fade-up">
                        {/* <i className="bx bx-shield"></i> */}
                        <img className='aboutIcons' src={securityIcon} />
                        <h4>
                          <div>
                            Compliance-Driven Security
                          </div>
                        </h4>
                        <p>
                          We prioritize strict security and compliance standards in everything we do. Our dedicated
                          efforts are aimed at protecting the trust of both you and your customers.
                        </p>
                      </div>
                      <div className="col-md-6 icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                        {/* <i className="bx bx-cube-alt"></i> */}
                        <img className='aboutIcons' src={secureIcon} />
                        <h4>
                          <div>
                            Secure Your Agreements
                          </div>
                        </h4>
                        <p>
                          Build unparalleled trust with your customers. With eZy Sign, send and sign documents with
                          confidence and peace of mind.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* ======= Features Section ======= */}
          <section id="features" className="features section-bg">
            <div className="container">
              <div className="section-title">
                <h2 data-aos="fade-in" className="aos-init">
                  Features
                </h2>
                {/* <p data-aos="fade-in" className="aos-init">
                  Magnam dolores commodi suscipit.
                </p> */}
              </div>

              {featureContent?.map((data, index) => {
                return (
                  <div key={index} className="row content justify-content-center align-items-center">
                    {/* <div className="col-md-3 order-1 order-md-2 aos-init" data-aos="fade-right"> */}
                    <div className={`col-md-3 order-1 order-md-${index%2===0 ? 2 : 1} aos-init`} data-aos="fade-right">
                      <img src={getFeatureImages()[index]} className="img-fluid" alt="" />
                    </div>
                    <div className={`col-md-7 order-2 order-md-${index%2===0 ? 1 : 2} pt-4 aos-init`} data-aos="fade-left">
                      <h3>{data?.heading}</h3>
                      <p className="font-italic">{data?.detail}</p>
                    </div>
                  </div>
                )
              })}

              {/* <div className="row content">
                <div className="col-md-5 order-1 order-md-2 aos-init" data-aos="fade-left">
                  <img src={featuresImage4} className="img-fluid" alt="" />
                </div>
                <div className="col-md-7 pt-5 order-2 order-md-1 aos-init" data-aos="fade-right">
                  <h3>Quas et necessitatibus eaque impedit ipsum animi consequatur incidunt in</h3>
                  <p className="font-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua.
                  </p>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                  </p>
                </div>
              </div> */}
            </div>
          </section>

          {/* ======= Portfolio Section ======= */}
          <section id="portfolio" className="portfolio section-bg">
            <div className="container">
              <div className="section-title">
                <h2 data-aos="fade-in" className="aos-init">
                  A Quick Glimpse
                </h2>
                {/* <p data-aos="fade-in" className="aos-init">
                  Magnam dolores commodi suscipit.
                </p> */}
              </div>

              <div className="row portfolio-container aos-init" data-aos="fade-up">
                {getProductSnapshots().map((image, index) => (
                  <div key={index} className="col-lg-4 col-md-6 portfolio-item filter-app">
                    <div className="portfolio-wrap">
                      <img data-gall="portfolioGallery" src={image} className="img-fluid" alt="" />
                      <div className="portfolio-links">

                        {/* <a href={image} data-gall="portfolioGallery" className="venobox vbox-item">
                          <i className="icofont-ui-zoom-in"></i>
                        </a> */}

                        <a className="venobox" href={image}>
                          <i className="icofont-ui-zoom-in"></i>
                        </a>

                      </div>
                      <div className="portfolio-info">
                        {/* <h4>App 1</h4> */}
                        <p>{portfolioContent[index]}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* ======= Pricing Section ======= */}
          <section id="pricing" className="pricing section-bg">
            <div className="container">
              <div className="section-title">
                <h2 data-aos="fade-in">{pricingContent?.pricing?.heading}</h2>
                <p data-aos="fade-in">{pricingContent?.pricing?.message}</p>
              </div>

              <div className="row no-gutters justify-content-between">
                {pricingContent?.pricing?.plans?.map((data, index) => (
                  <div key={index} className="col-lg-6 box" data-aos="zoom-in">
                    <h3>{data?.name}</h3>
                    <h4>
                      {data?.price}
                      <span>{data?.timePeriod}</span>
                    </h4>
                    <ul>
                      {data?.features?.map((featureData, featureIndex) => (
                        <li key={featureIndex}>
                          <i className="bx bx-check"></i>
                          <div>{featureData}</div>
                        </li>
                      ))}
                    </ul>
                    {/* <a href="#" className="get-started-btn">
                      Get Started
                    </a> */}
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* ======= Frequently Asked Questions Section ======= */}
          <section id="faq" className="faq section-bg">
            <div className="container">
              <div className="section-title">
                <h2 data-aos="fade-in">Frequently Asked Questions</h2>
                {/* <p data-aos="fade-in">
                  Magnam dolores commodi suscipit.
                </p> */}
              </div>
              {faqContent.map((data, index) => (
                <div
                  key={index}
                  className="row faq-item d-flex align-items-stretch"
                  data-aos="fade-up"
                  data-aos-delay="0">
                  <div className="col-lg-5">
                    <i className="bx bx-help-circle"></i>
                    <h4>{data?.question}</h4>
                  </div>
                  <div className="col-lg-7">
                    <p>{data?.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* ======= Contact Section ======= */}
          <section id="contact" className="contact section-bg">
            <div className="container">

              <div className="section-title">
                <h2 data-aos="fade-in" className="aos-init">
                Contact Us
                </h2>
                <div>
                  <span className='d-block' data-aos="fade-up">
                    Need help using eZy Sign functionality?
                  </span>
                  <span>Complete the form, submit it, and we'll get in touch with you.</span>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 leftSide align-items-center align-items-lg-start mt-3 mt-lg-5">
                  {/* <div className="section-title text-left pb-0">
                    <h2 data-aos="fade-in">Contact Us</h2>
                  </div> */}

                  

                  <img src={require("../../assets/indexPage/contactus.png")} alt="" className="img-fluid mr-2"/>

                  {/* <div className="row">
                    <div className="col-md-12">
                      <p data-aos="fade-up">
                        Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit
                        sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
                        Quia fugiat sit in iste officiis commodi quidem hic quas.
                      </p>
                      <div className="info-box" data-aos="fade-up">
                        <i className="bx bx-map"></i>
                        <h3>Our Address</h3>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="rightSide col-lg-6">
                  {/* <form id="contact-form" action="forms/contact.php" method="post" role="form" className="php-email-form" data-aos="fade-up">
                <div className="form-row">
                  <div className="col-md-6 form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                    <div className="validate"></div>
                  </div>
                  <div className="col-md-6 form-group">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                    <div className="validate"></div>
                  </div>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:8" data-msg="Please enter at least 8 chars of subject" />
                  <div className="validate"></div>
                </div>
                <div className="form-group">
                  <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                  <div className="validate"></div>
                </div>
                <div className="mb-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div className="text-center"><button type='submit' className="formSubmitBtn text-18-500" value="Submit">Send Message</button></div>
              </form> */}

                  <div className="contactUsFormSection">
                    <div className="formContainer">
                      <div id="contactusFormStatusDiv" style={contactStatus?.message  ? contactStatus?.error ? {background: "#f7d2da"} : {background: "#d0ebdc"} : {background: "transparent"}} >
                        <div id="statusInnerDiv" style={contactStatus?.message ? {display: "flex"} : {display: "none"}}>
                          <p id="contactusFormStatuPara">{contactStatus?.message || "This filed can't be blank"}</p>
                          {/* <img className="close-btn" src="{{asset "images/closeButton.svg"}}" onclick="hideContactFormResponse()"/> */}
                        </div>
                      </div>

                      <form id="contact-form" className="php-email-form">
                        <div className="customInputGroup">
                          <div>
                            <input className="formInput" id="username" placeholder="Name*" />
                          </div>
                          <div>
                            <input className="formInput" id="email" placeholder="Email*" />
                          </div>
                        </div>

                        <div className="customInputGroup">
                          <div>
                            <textarea className="formInput" id="message" placeholder="Enter your message*"></textarea>
                          </div>
                        </div>
                        <input type="hidden" id="recaptcha-response" name="recaptcha-response" />

                        <div className="d-flex justify-content-start">
                          <button
                            onClick={(e) => handleContactSubmitBtn(e)}
                            className="text-center contactSubmitBtn"
                            value="Submit">
                            Send Message
                            <span className="spinner"></span>
                          </button>
                        </div>

                        <ReCAPTCHA className='mt-2' ref={recaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} />

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <footer id="footer">
          <div className="footer-top">
            <div className="container aos-init" data-aos="fade-up">
              <div className="row  justify-content-center">
                <div className="section-title col-lg-11 pb-0">
                  <h2 className="text-white">Experience it for yourself!</h2>
                  <p>Don't miss out on the app, it's your ticket to digital greatness. Download today and unlock seamless eSign solutions for your needs!</p>

                  <ul className="download-badges m-4 p-0">
                    <li>
                      <a
                        target="_blank"
                        href="https://apps.apple.com/pk/app/scan-esign-fill-docs-lite/id971648827"
                        title=""
                        rel="noreferrer"
                        className="badge-appstore aos-init aos-animate"
                        data-aos="fade-up">
                        Download Photo App for Apple iOS Pro
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="clearfix">
                {/* <div className="copyright">
                  © Copyright{' '}
                  <strong>
                    <span>Bocor</span>
                  </strong>
                  . All Rights Reserved
                </div> */}

                <div className="copyright">
                {/* © */}
                    <span className='' >Digitally Sign, Scan, Fill PDF</span>
                    <span className='' >Developed by <a target="_blank" rel="nofollow" href="https://www.whizpool.com/">Whizpool</a></span>
                    <span style={{display: "inline", width: "minContent"}}><a className="" target="_blank" href="/privacy.html">Privacy</a></span>
                    <span style={{display: "inline", width: "minContent"}}><a className="ml-2 ml-lg-0" target="_blank" href="/terms.html">Terms</a></span>
                </div>



                {/* <div className="credits">
                  Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                </div> */}
              </div>

              {/* <div className="row footer-newsletter justify-content-center">
                <div className="col-lg-6">
                  <form action="file:///C:/Users/Lenovo/Downloads/bocor-master/index.html" method="post">
                    <input type="email" name="email" placeholder="Enter your Email" />
                    <input type="submit" value="Subscribe" />
                  </form>
                </div>
              </div> */}

              {/* <div className="social-links">
                <a href="file:///C:/Users/Lenovo/Downloads/bocor-master/index.html#" className="twitter">
                  <i className="bx bxl-twitter"></i>
                </a>
                <a href="file:///C:/Users/Lenovo/Downloads/bocor-master/index.html#" className="facebook">
                  <i className="bx bxl-facebook"></i>
                </a>
                <a href="file:///C:/Users/Lenovo/Downloads/bocor-master/index.html#" className="instagram">
                  <i className="bx bxl-instagram"></i>
                </a>
                <a href="file:///C:/Users/Lenovo/Downloads/bocor-master/index.html#" className="google-plus">
                  <i className="bx bxl-skype"></i>
                </a>
                <a href="file:///C:/Users/Lenovo/Downloads/bocor-master/index.html#" className="linkedin">
                  <i className="bx bxl-linkedin"></i>
                </a>
              </div> */}
            </div>
          </div>

          
        </footer>

        <button className="back-to-top" onClick={handleScrollToTop}>
          <i className="icofont-simple-up"></i>
        </button>
      </div>
      <div className="mobile-nav-overly"></div>
    </>
  );
};

export default HomePage2;

// /* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import logo from '../../assets/images/logo-white.svg';
import { Box } from '@mui/material';

const Navbar = () => {
  const [remainingTime, setRemainingTime] = useState('--:--');
  let { documentName, expiryDate } = useSelector(
    (state) => ({
      documentName: state?.document?.document_detail?.name,
      expiryDate: state?.document?.document_detail?.expiryDate
    }),
    shallowEqual
  );

  function getExpiryTime(expiryTimestamp) {
    const millisecondsInHour = 1000 * 60 * 60;
    const millisecondsInDay = millisecondsInHour * 24;
    const millisecondsInMonth = millisecondsInDay * 30;
    const millisecondsInYear = millisecondsInDay * 365;

    const customDate = new Date();
    customDate.setFullYear(2023); // Set the year (e.g., 2023)
    customDate.setMonth(6); // Set the month (January is 0, so 6 is July)
    customDate.setDate(31); // Set the day of the month (1-31)
    customDate.setHours(14); // Set the hours (24-hour format)

    const expiryDate = new Date(expiryTimestamp);
    // const expiryDate = new Date(customDate);
    const currentDate = new Date();

    let timeDifference = expiryDate - currentDate;
    // let timeDifference = expiryDate;
    const years = Math.floor(timeDifference / millisecondsInYear);
    timeDifference %= millisecondsInYear;

    const months = Math.floor(timeDifference / millisecondsInMonth);
    timeDifference %= millisecondsInMonth;

    const days = Math.floor(timeDifference / millisecondsInDay);
    timeDifference %= millisecondsInDay;

    const hours = Math.floor(timeDifference / millisecondsInHour);
    timeDifference %= millisecondsInDay;

    if (timeDifference > 0) {
      if (years >= 1) {
        return `${years} year${years > 1 ? 's' : ''}, ${months} month${months > 1 ? 's' : ''}`;
      } else if (months >= 1) {
        return `${months} month${months > 1 ? 's' : ''}, ${days} day${days > 1 ? 's' : ''}`;
      } else if (days >= 1) {
        return `${days} day${days > 1 ? 's' : ''}, ${hours} hour${hours > 1 ? 's' : ''}`;
      } else {
        return `${hours} hour${hours > 1 ? 's' : ''}`;
      }
    } else {
      return 'expired';
    }
  }

  useEffect(() => {
    if (expiryDate) {
      const date = getExpiryTime(expiryDate);
      setRemainingTime(date);
    }
  }, [expiryDate]);

  return (
    <Fragment>
      <Box className="smallScreen nav-header d-flex justify-content-start px-3">
        <div className="logo">
          <img src={logo} />
        </div>
        <div className="d-flex justify-content-between w-100 align-items-center">
          <span className="title mx-3 font-weight-bold">eZy Sign</span>
          <span className="help">Help</span>
        </div>
      </Box>

      <nav className="navbar">
        <div className="navbarInner d-flex justify-content-between align-items-center w-100">
          <div className="">
            <div className="logo">
              <img src={logo} />
            </div>
          </div>
          <div className="col d-flex justify-content-center navbarTitleDiv">
            <span className="title font-weight-bold">{documentName || 'Your Document'}</span>
          </div>
          <div className="text-right">
            <div className="expiry">
              <span>Expires in: {remainingTime}</span>
              {/* <span className="mx-3">|</span>
              <span>Help</span> */}
            </div>
          </div>
        </div>
        {/* <button className="btn btn-light" onClick={signOut}>
          Signout
        </button> */}
      </nav>
    </Fragment>
  );
};

Navbar.propTypes = {};

export default React.memo(Navbar);

/* eslint-disable*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import React from 'react';

const FormTextField = ({ data }) => {
  const multilineprops = {};
  if (data?.multiline) {
    multilineprops.multiline = true;
    (multilineprops.rows = 5), (multilineprops.maxRows = 5);
  }
  return (
    <div className="fieldSize">
      <TextField
        id={data.id}
        className="formControl"
        type="text"
        autoComplete="off"
        name={data.name}
        label={data.label}
        placeholder={data.placeholder}
        value={data.value}
        onChange={data.handleChange}
        onBlur={data.handleBlur}
        required={data.required}
        error={data.error && data.touched ? true : false}
        disabled={data.disabled ? data.disabled : false}
        size="small"
        autoFocus={true}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ alignSelf: data?.multiline ? 'start' : 'center', marginTop: data?.multiline ? '11px' : '0px' }}>
              <FontAwesomeIcon icon={data.icon} color="#747474" />
            </InputAdornment>
          )
        }}
        InputLabelProps={{ shrink: true }}
        // multiline={data?.multiline}
        // multiline
        // rows={5}
        // maxRows={5}
        {...multilineprops}
      />
      <div className="errorfield">{data.error && data.touched ? <span>{data.error}</span> : null}</div>
    </div>
  );
};

FormTextField.propTypes = {
  data: PropTypes.object
};

export default FormTextField;
// export default React.memo(FormTextField)

import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router';
import Navbar from '../components/header-footer/Navbar';
// import { fbConfig } from '../config/fbConfig';

const AuthenticatedLayout = () => {
  const location = useLocation();
  const authState = useSelector((state) => {
    return state.auth;
  });

  // const signOut = async (e) => {
  //   e.preventDefault();
  //   try {
  //     fbConfig
  //       .signOut()
  //       .then(() => {
  //         localStorage.removeItem('authToken');
  //         navigate('/login');
  //       })
  //       .catch((error) => {
  //         console.log(0, error);
  //       });
  //   } catch (error) {
  //     console.log(1, error);
  //   }
  // };

  return (
    <Fragment>
      {useMemo(
        () => (
          <Navbar />
        ),
        [authState.isLogin]
      )}

      {authState.isLogin ? <Outlet /> : <Navigate to="/" replace state={{ from: location }} />}
    </Fragment>
  );
};
export default AuthenticatedLayout;

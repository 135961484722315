/* eslint-disable */
import Button from '@mui/material/Button';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Document, Page, pdfjs } from 'react-pdf';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { json, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/app-components/Loader';
import PageNumber2 from '../../components/app-components/PageNumber2';
import AttachmentDropdownMenu from '../../components/document-components/AttachmentDropdownMenu';
import DocumentStatus from '../../components/app-components/DocumentStatus';
import FieldsDropdownMenu from '../../components/document-components/DropdownMenu';
import OptionDropDownMenu from '../../components/document-components/OptionDropdownMenu';
import {
  AUTH_FAILED,
  DOCUMENT_LOADING,
  GET_DOCUMENT_DETAIL_FAIL,
  GET_DOCUMENT_DETAIL_SUCCESS
} from '../../redux/action-types';
import * as documentActions from '../../redux/document/document-action';
import * as fileActions from '../../redux/file/file-action';
import EditSignModal from './EditSignModal';
import EditTextModal from './EditTextModal';
import SuccessModal from '../../components/app-components/SuccessModal';
import '../../assets/css/view-document.css';
import DocumentHeader from '../../components/document-components/DocumentHeader';
import { Stage, Layer, Rect, Text, Image } from 'react-konva';
import _, { isObject } from 'lodash';

import { isCoordinatesExist, deepCopyObject, isObjectEmpty, appendUint8Arrays } from '../../utils/utilityFunctions';
import { drawImageToPdf, drawTextToPdf, scrollToPage } from '../../utils/pdfRelatedFunctions';
import EditDateModal from './EditDateModal';
import CustomCanvasText from './CustomCanvasText';
import ProgressModal from '../../components/app-components/ProgressModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewDocument2 = () => {
  let {
    documentId,
    documentFilePath,
    documentDisplayName,
    attachmentArray,
    documentStatus,
    expiryDate,
    annotationsArray
  } = useSelector(
    (state) => ({
      documentId: state?.document?.document_detail?.id,
      documentFilePath: state?.document?.document_detail?.filePath,
      documentDisplayName: state?.document?.document_detail?.name || 'signAndFill',
      attachmentArray: state?.document?.document_detail?.attachments || [],
      documentStatus: state?.document?.document_detail?.status,
      expiryDate: state?.document?.document_detail?.expiryDate,
      annotationsArray: state?.document?.document_detail?.annotations?.items || null
    }),
    shallowEqual
  );

  const annotationsOrignal_ref = useRef(null);
  const [pageDimensions, setPageDimensions] = useState([]);
  const [pageDimensionsWithRotation, setPageDimensionsWithRotation] = useState([]);
  const firstPageWidth_ref = useRef(null);
  const layerRef = useRef();
  const clickedItemid_ref = useRef('');

  const [pagesAndAnnots, setPagesAndAnnots] = useState({});
  const [initialCanvasPagesAndAnnots, setInitialCanvasPagesAndAnnots] = useState({});

  const [pagesAndSignAnnots, setPagesAndSignAnnots] = useState({});
  const fontSize = 16;
  const customLineHeight = 1;
  // const canvasFontAdjustFactor = 1.9; //setting for current font family
  const canvasFontAdjustFactor = 3.4; //setting for current font family
  const pdfFontAdjustFactor = 3.4;
  const fontSizeAdjustFactor = 1.15;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [previewPageList, setPreviewPageList] = useState([]);
  const [isDocLoading, setIsDocLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [doneDisabled, setDoneDisabled] = useState(false);
  const [file, setFile] = useState(null);
  const [bufferData, setBufferData] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [formFields, setFormFields] = useState([]);
  const [openSignature, setOpenSignature] = useState(false);
  const [documentHeight, setDocumentHeight] = useState(window.innerHeight - 130);
  const [docPaddingTop, setDocPaddingTop] = useState(130);
  const [filledFields, setFilledFields] = useState([]);
  const [fieldsNameMap, setFieldsNameMap] = useState({});
  const [documentUrl, setDocumentUrl] = useState('');

  const [signatureSeletedField, setSignatureSeletedField] = useState('');
  const [allAnnotationsInfo, setAllAnnotationsInfo] = useState([]);
  const [annotationsList, setAnnotationsList] = useState([]);
  const [modifiedAttachmentArray, setModifiedAttachmentArray] = useState([]);

  const [textModalState, setTextModalState] = useState({
    open: false
  });
  const [dateModalState, setDateModalState] = useState({
    open: false
  });
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openProgressModal, setOpenProgressModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDocEditMode, setIsDocEditMode] = useState(true);
  const [pdfWidestPageWidth, setPdfWidestPageWidth] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isPdfFetching, setIsPdfFetching] = useState(false);
  const [processingString, setProcessingString] = useState('');
  const [uploadingAttachNo, setUploadingAttachNo] = useState(1);
  const [totalAttach, setTotalAttach] = useState(0);
  // const internetFailureThreshold = 15000 // time in miliSeconds

  const indexNameArray = useRef([]);
  const bufferDataOrignal_ref = useRef(null);
  const bufferData_ref = useRef(null);
  const pdfWidestPageWidth_ref = useRef(0);
  const defaultZoomLevel_ref = useRef(1);
  const annotHoverColor = '#fdff0075';

  const handleMouseEnterOnAnnot = (index_page, index_rect) => {
    if (Number.isInteger(index_page) && Number.isInteger(index_rect)) {
      pagesAndAnnots[index_page + 1][index_rect].isHoverColor = true;
      setPagesAndAnnots((prevState) => ({
        ...prevState
      }));
    }
  };
  const handleMouseLeaveOnAnnot = (index_page, index_rect) => {
    if (Number.isInteger(index_page) && Number.isInteger(index_rect)) {
      pagesAndAnnots[index_page + 1][index_rect].isHoverColor = false;
      setPagesAndAnnots((prevState) => ({
        ...prevState
      }));
    }
  };
  // const addItemsToPage = (pageNumber, items) => {
  //   const groupAnnotationByPagenumber = _.groupBy(annotationsArray, 'pageNumber');
  //   setPagesAndAnnots((prevPagesAndItems) => ({
  //     ...prevPagesAndItems,
  //     [pageNumber]: items,
  //   }));
  // };

  const annot_initialBackgroundColor = '#4fa9fb4f';
  const annot_updatedBackgroundColor = undefined;
  const annot_errorBackgroundColor = '#ff000050';
  const annot_initialBorderColor = '#000000';
  const annot_errorBorderColor = '#ff000050';

  useEffect(() => {
    if (annotationsArray?.length > 0) {
      // const tempPagesAndAnnots = deepCopyObject(annotationsArray);
      const tempPagesAndAnnots = [...annotationsArray];
      for (const annotation of tempPagesAndAnnots) {
        annotation.isUpdated = false;
        annotation.borderColor = annot_initialBorderColor;
        annotation.backgroundColor = annot_initialBackgroundColor;
      }
      if (tempPagesAndAnnots?.length > 0) {
        const groupAnnotationByPagenumber = _.groupBy(tempPagesAndAnnots, 'pageNumber');
        const groupAnnotationByPagenumber2 = deepCopyObject(_.groupBy(tempPagesAndAnnots, 'pageNumber'));
        setInitialCanvasPagesAndAnnots(groupAnnotationByPagenumber2);
        setPagesAndAnnots(groupAnnotationByPagenumber);
      }

      const tempPagesAndSignAnnots = deepCopyObject(annotationsArray);
      if (!isObjectEmpty(tempPagesAndSignAnnots)) {
        const groupSignAnnotsByPageNo = _.groupBy(tempPagesAndSignAnnots, 'pageNumber');
        setPagesAndSignAnnots(groupSignAnnotsByPageNo);
      }
    }
  }, [annotationsArray]);

  const clearCanvas = () => {
    // Access the Konva Layer instance and clear it
    // layerRef.current.clear();
    // console.log("dsvbgytrgv ", annotationsOrignal_ref);
    // if(!isObjectEmpty(annotationsOrignal_ref.current)){
    //   const shallowPagesAndAnnots = deepCopyObject(annotationsOrignal_ref.current);
    //   setPagesAndAnnots(shallowPagesAndAnnots)
    // }

    if (!isObjectEmpty(initialCanvasPagesAndAnnots)) {
      const tempPagesAndAnnots = deepCopyObject(initialCanvasPagesAndAnnots);
      setPagesAndAnnots(tempPagesAndAnnots);
    }
  };

  const clearAttachments = () => {
    const updatedAttachmentArray = attachmentArray.map((obj) => {
      return { ...obj, selectedFileDetail: {} };
    });
    setModifiedAttachmentArray(updatedAttachmentArray);
  };

  const canvasToPdfCoordinates = (props_of_canvas_item, index_page, cropBoxX, cropBoxY) => {
    if (
      isCoordinatesExist(props_of_canvas_item) &&
      index_page !== null &&
      index_page !== undefined &&
      numPages !== null &&
      numPages !== undefined &&
      index_page > -1 &&
      index_page < numPages &&
      pageDimensions[index_page]?.width &&
      pageDimensions[index_page]?.height
    ) {
      const new_y =
        pageDimensions[index_page]?.height -
        (props_of_canvas_item.y * pageDimensions[index_page]?.height +
          props_of_canvas_item.height * props_of_canvas_item.width * pageDimensions[index_page]?.width);
      const pdfCoordinates = {
        x: props_of_canvas_item.x * pageDimensions[index_page]?.width + cropBoxX,
        y: new_y + cropBoxY,
        width: props_of_canvas_item.width * pageDimensions[index_page]?.width,
        height: props_of_canvas_item.height * props_of_canvas_item.width * pageDimensions[index_page]?.width
      };
      // console.log("gtrhtrvhnfvfver: ", props_of_canvas_item, pdfCoordinates);
      return pdfCoordinates;
    } else {
      return null;
    }
  };

  const updatePdfFromCanvas = async (pdfDoc) => {
    if (bufferDataOrignal_ref?.current && pagesAndAnnots && pdfDoc) {
      // console.log(`dsvgfbtrvfd: `, pagesAndAnnots);
      // Object.entries(pagesAndAnnots).forEach(([key, value]) => {
      //   console.log(`dsvgfbtrvfd: ${key}: ${value}`);
      // });

      for (const key in pagesAndAnnots) {
        if (pagesAndAnnots.hasOwnProperty(key)) {
          const annotationsArrayList = pagesAndAnnots[key];
          for (const annotation of annotationsArrayList) {
            const pageNumber = annotation?.pageNumber - 1;
            const page = pdfDoc.getPage(pageNumber);

            const { width: pageWidth, height: pageHeight } = page.getSize();
            const { x: cropBoxX, y: cropBoxY, width: cropBoxWidth, height: cropBoxHeight } = page.getCropBox();

            const pdfCoordinates = canvasToPdfCoordinates(annotation, pageNumber, cropBoxX, cropBoxY);
            if (cropBoxWidth === pageWidth && cropBoxHeight === pageHeight) {
              page.setCropBox(0, 0, pageDimensions[pageNumber].width, pageDimensions[pageNumber].height);
              page.setMediaBox(0, 0, pageDimensions[pageNumber].width, pageDimensions[pageNumber].height);
            }

            if (annotation?.type === 'text' || annotation?.type === 'multiline' || annotation?.type === 'date') {
              // const fontSizeFromJson = annotation?.fontSize * pageDimensions[key-1]?.width * pdfFontAdjustFactor;
              const fontSizeFromJson = annotation?.fontSize * pageDimensions[key - 1]?.width * fontSizeAdjustFactor;
              await drawTextToPdf(pdfDoc, pageNumber, annotation, pdfCoordinates, fontSizeFromJson, customLineHeight);
            }
            if (annotation?.type === 'signature') {
              await drawImageToPdf(pdfDoc, pageNumber, annotation, pdfCoordinates);
            }
          }
        }
      }

      const updatedPdfBytes = await pdfDoc.save();
      // bufferData_ref.current = updatedPdfBytes;
      bufferData_ref.current = updatedPdfBytes.buffer;
      setBufferData(updatedPdfBytes.buffer);

      // below code update the pdf in browser
      // const updatedPdfBlob = new Blob([updatedPdfBytes], { type: 'application/pdf' });
      // const docUrl = URL.createObjectURL(updatedPdfBlob);
      // setFile(docUrl);

      // console.log("dsvgfbtrvfd ", pagesAndAnnots);
      // toast.success("pdf has been updated successfully");
      return true;
    }
    // console.log("fdbgrbvv ", bufferData);
    // toast.error("canvas to pdf updation failed");
    return false;
  };

  const handleClick = async (e, index_page, index_item) => {
    if (
      pagesAndAnnots[index_page + 1] &&
      pagesAndAnnots[index_page + 1][index_item] &&
      pagesAndAnnots[index_page + 1][index_item]?.id
    ) {
      clickedItemid_ref.current = pagesAndAnnots[index_page + 1][index_item].id;
    }

    if (
      pagesAndAnnots[index_page + 1][index_item]?.type === 'text' ||
      pagesAndAnnots[index_page + 1][index_item]?.type === 'multiline'
    ) {
      setTextModalState((prevState) => ({
        ...prevState,
        open: true,
        textValue: pagesAndAnnots[index_page + 1][index_item]?.isUpdated
          ? pagesAndAnnots[index_page + 1][index_item].textValue
          : '',
        id: pagesAndAnnots[index_page + 1][index_item].id,
        type: pagesAndAnnots[index_page + 1][index_item].type,
        pageNumber: pagesAndAnnots[index_page + 1][index_item].pageNumber,
        fontSize: pagesAndAnnots[index_page + 1][index_item].fontSize * pageDimensions[index_page]?.width
      }));
    }

    if (pagesAndAnnots[index_page + 1][index_item]?.type === 'date') {
      setDateModalState((prevState) => ({
        ...prevState,
        open: true,
        textValue: pagesAndAnnots[index_page + 1][index_item].textValue,
        id: pagesAndAnnots[index_page + 1][index_item].id,
        pageNumber: pagesAndAnnots[index_page + 1][index_item].pageNumber,
        fontSize: pagesAndAnnots[index_page + 1][index_item].fontSize * pageDimensions[index_page]?.width
      }));
    }

    if (pagesAndAnnots[index_page + 1][index_item]?.type === 'signature') {
      setOpenSignature(true);
    }
  };

  const [annotFieldsStatusArray, setAnnotFieldsStatusArray] = useState([]);

  useEffect(() => {
    const tempAnnotFieldsStatusArray = [];
    const tempPagesAndAnnots = deepCopyObject(pagesAndAnnots);

    if (!isObjectEmpty(tempPagesAndAnnots)) {
      for (const [currentPageNo, annotationsOnPage] of Object.entries(tempPagesAndAnnots)) {
        if (annotationsOnPage && annotationsOnPage.length > 0) {
          annotationsOnPage.forEach((annot, index) => {
            if (annot?.id && annot?.pageNumber) {
              tempAnnotFieldsStatusArray.push({
                id: annot?.id || null,
                fieldName: annot?.fieldName || 'field box',
                pageNumber: annot?.pageNumber || null,
                isUpdated: annot?.isUpdated || false,
                isOptional: annot?.isOptional || false
              });
            }
          });
        }
      }

      if (tempAnnotFieldsStatusArray.length > 0) {
        if (!_.isEqual(annotFieldsStatusArray, tempAnnotFieldsStatusArray)) {
          // console.log("tyrtbvetb ", annotFieldsStatusArray, pagesAndAnnots);
          setAnnotFieldsStatusArray(tempAnnotFieldsStatusArray);
        }
      }
    }
  }, [pagesAndAnnots]);

  // console.log("kjdsvireuyrtv ", annotFieldsStatusArray);

  const coverScreenWidth = () => {
    // const pdfWidthPortrait = 0.8;
    // const pdfWidthLandscape = 0.5;
    // const thresholdFactor = 1.5;
    // const screenWidth = window.innerWidth;
    // const screenHeight = window.innerHeight;

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const maxVmin = 100; // Set your max-width value in vmin
    const vminBorderRangeInPercent = 0.2; //20% border area between screenWidth and screenHeight
    const maxWidthInPercent = 0.9;
    const resultInPixels = (Math.min(screenWidth, screenHeight) * maxVmin) / 100;
    const screenWidthInPercentage = (resultInPixels / screenWidth).toFixed(2);
    const percentDiffBetweenWidthAndHeight = Math.abs(
      (screenWidth - screenHeight) / ((screenWidth + screenHeight) / 2)
    );
    if (
      (percentDiffBetweenWidthAndHeight < vminBorderRangeInPercent && screenWidthInPercentage > maxWidthInPercent) ||
      (screenHeight > screenWidth && screenWidthInPercentage > maxWidthInPercent)
    ) {
      return maxWidthInPercent;
    }
    return screenWidthInPercentage;
  };

  useEffect(() => {
    if (pdfWidestPageWidth > 0) {
      // defaultZoomLevel_ref.current = ((window.innerWidth * 0.8)/pdfWidestPageWidth) * window.devicePixelRatio;
      // const coverScreenWidth = window.innerWidth>window.innerHeight ? 0.5 : 0.8;

      defaultZoomLevel_ref.current = (window.innerWidth * coverScreenWidth()) / pdfWidestPageWidth;
      setScale(defaultZoomLevel_ref.current);
      // setScale(3);
    }
  }, [pdfWidestPageWidth]);

  useEffect(() => {
    // below code is to add fieldName for each annotation just for temporary purpose
    // let textFieldNo = 0;
    // let multilineFieldNo = 0;
    // let signatureFieldNo = 0;
    // let dataFieldNo = 0;
    // if(annotationsArray && annotationsArray.length > 0){
    //   annotationsArray.forEach((elem, index)=>{
    //     if(elem.type==="text") {
    //       textFieldNo++;
    //       elem.fieldName = "textbox_" + textFieldNo;
    //     }
    //     if(elem.type==="multiline") {
    //       multilineFieldNo++;
    //       elem.fieldName = "multiline_" + multilineFieldNo;
    //     }
    //     if(elem.type==="signature") {
    //       signatureFieldNo++;
    //       elem.fieldName = "signature_" + signatureFieldNo;
    //     }
    //     if(elem.type==="date") {
    //       dataFieldNo++;
    //       elem.fieldName = "date_" + dataFieldNo;
    //     }
    //   });
    // }

    // console.log("lmkmjnyu ", annotationsArray);

    if (annotationsArray && annotationsArray.length > 0) {
      const groupAnnotationByPagenumber = _.groupBy(annotationsArray, 'pageNumber');
      annotationsOrignal_ref.current = deepCopyObject(groupAnnotationByPagenumber);
    }
  }, [annotationsArray]);

  // setting the width of pdf
  let initialWidth = 0;
  // if window width is greater than 1000px
  if (window.innerWidth > 1000) {
    initialWidth = 0.55 * window.innerWidth;
  } else {
    initialWidth = 0.8 * window.innerWidth;
  }
  initialWidth = parseFloat(initialWidth.toFixed(1));

  const [scale, setScale] = useState(1);
  const pdfDocWidth = initialWidth;

  //docScrollBarSize include 24px of padding
  const docScrollBarSize = 12 + 24;

  const loadImageFromUri = (signImage) => {
    return new Promise((resolve, reject) => {
      signImage.onload = function () {
        const width = signImage.width;
        const height = signImage.height;
        resolve({ signImageWidth: width, signImageHeight: height });
      };

      signImage.onerror = function (error) {
        reject(error);
      };
    });
  };

  const updateSignatureAnnotById = async (fieldId, signImageUri) => {
    // const fieldData = openSignature;

    const fieldData = getAnnotById_shallowCopy(fieldId);
    // let signImageWidth = null;
    // let signImageHeight = null;

    const signImage = new window.Image();
    signImage.src = signImageUri;

    const { signImageWidth, signImageHeight } = await loadImageFromUri(signImage);

    setPagesAndAnnots((prevState) => {
      // const newState = deepCopyObject(prevState);
      const newState = { ...prevState };
      for (const pageNumber in newState) {
        if (newState.hasOwnProperty(pageNumber)) {
          const annotationsOnPages = newState[pageNumber];
          const annotationToUpdate = annotationsOnPages.find((currentAnnot) => currentAnnot.id === fieldId);
          // If the annotation with the specified 'id' is found, update its 'textValue'
          if (annotationToUpdate) {
            // console.log("gbdthrjum 1", fieldId, fieldData, annotationToUpdate, pagesAndAnnots );
            annotationToUpdate.isUpdated = true;
            annotationToUpdate.signatureUri = signImage;
            annotationToUpdate.backgroundColor = annot_updatedBackgroundColor;
            annotationToUpdate.borderColor = annot_initialBorderColor;
            break;
          }
        }
      }

      return newState;
    });

    //signature position coOrdinates on canvas page
    let position_x = fieldData?.x * pageDimensions[fieldData?.pageNumber - 1].width * scale;
    let position_y = fieldData?.y * pageDimensions[fieldData?.pageNumber - 1].height * scale;
    //signature container size on canvas page
    let signBoxWidth = fieldData?.width * pageDimensions[fieldData?.pageNumber - 1].width * scale;
    let signBoxHeight = fieldData?.height * fieldData?.width * pageDimensions[fieldData?.pageNumber - 1].width * scale;

    let hRatio = signBoxWidth / signImageWidth;
    let vRatio = signBoxHeight / signImageHeight;
    let ratio = Math.min(hRatio, vRatio);
    let centerShift_x = (signBoxWidth - signImageWidth * ratio) / 2;
    let centerShift_y = (signBoxHeight - signImageHeight * ratio) / 2;

    // console.log("gbdthrjum 2", fieldId, fieldData, position_x + centerShift_x, position_y + centerShift_y, signImageWidth * ratio, signImageHeight * ratio );

    setPagesAndSignAnnots((prevState) => {
      for (const pageNumber in prevState) {
        if (prevState.hasOwnProperty(pageNumber)) {
          const annotationsOnPages = prevState[pageNumber];
          const annotationToUpdate = annotationsOnPages.find((currentAnnot) => currentAnnot.id === fieldId);
          // If the annotation with the specified 'id' is found, update its 'textValue'
          if (annotationToUpdate) {
            (annotationToUpdate.x = position_x + centerShift_x),
              (annotationToUpdate.y = position_y + centerShift_y),
              (annotationToUpdate.width = signImageWidth * ratio),
              (annotationToUpdate.height = signImageHeight * ratio);
            break;
          }
        }
      }
      // console.log("sxdcrefedfvtb ", annotationsOrignal_ref.current, prevState );
      return prevState;
    });
  };

  const updateTextAnnotationById = (fieldId, newTextValue) => {
    // const id = "2C20D03E-3631-4A37-8FD5-C89398109426"

    // console.log("scdfiuyt ", fieldId, newTextValue);

    const annotationToUpdate = getAnnotById_refCopy(fieldId);
    if (annotationToUpdate) {
      annotationToUpdate.textValue = newTextValue;
      annotationToUpdate.isUpdated = true;
      annotationToUpdate.backgroundColor = annot_updatedBackgroundColor;
      annotationToUpdate.borderColor = annot_initialBorderColor;
    }
    setPagesAndAnnots((prevState) => ({
      ...prevState
    }));
  };

  const getAnnotById_refCopy = (fieldId) => {
    if (!isObjectEmpty(pagesAndAnnots)) {
      for (const pageNo in pagesAndAnnots) {
        if (pagesAndAnnots.hasOwnProperty(pageNo)) {
          const annotationsOnPage = pagesAndAnnots[pageNo];
          if (annotationsOnPage.length > 0) {
            const annotationToGet = annotationsOnPage.find((currentAnnot) => currentAnnot.id === fieldId);
            if (!isObjectEmpty(annotationToGet)) {
              return annotationToGet;
            }
          }
        }
      }
    }
    return null;
  };

  const getAnnotById_shallowCopy = (fieldId) => {
    const tempPagesAndAnnots = deepCopyObject(pagesAndAnnots);
    if (!isObjectEmpty(tempPagesAndAnnots)) {
      for (const pageNo in tempPagesAndAnnots) {
        if (tempPagesAndAnnots.hasOwnProperty(pageNo)) {
          const annotationsOnPage = tempPagesAndAnnots[pageNo];
          if (annotationsOnPage.length > 0) {
            const annotationToGet = annotationsOnPage.find((currentAnnot) => currentAnnot.id === fieldId);
            if (!isObjectEmpty(annotationToGet)) {
              return annotationToGet;
            }
          }
        }
      }
    }
    return null;
  };

  const updateFieldData = async (fieldData) => {
    // console.log("dfgrtvfc ", fieldData);
    updateTextAnnotationById(fieldData?.id, fieldData?.textValue);
  };

  const applySignature = async (fieldId, imageUri) => {
    // console.log("asdvbtrgbg fieldId ", fieldId);
    // console.log("asdvbtrgbg imageUri ", imageUri);

    updateSignatureAnnotById(fieldId, imageUri);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setIsDocLoading(false);
    setNumPages(numPages);
  }

  const loadFile = async (dataBuffer) => {
    // const pdfDoc = await PDFDocument.load(dataBuffer);
    // console.log("asdverdsre 1", dataBuffer);
    const pdfDoc = await PDFDocument.load(dataBuffer, { ignoreEncryption: true });
    if (pdfDoc) {
      // console.log("asdverdsre 2", dataBuffer, pdfDoc);
      // setPageNoArray(generatePageNoArray(pdfDoc.getPageCount()));

      // const allFieldsInfo = saveFieldsIndexAndName(pdfDoc);
      // setAllAnnotationsInfo(allFieldsInfo);
      // const outputList = allFieldsInfo.flatMap((obj) => Object.values(obj)[0]);
      // formFieldNamesHandler(outputList);
      // if (outputList?.length > 0) {
      //   setAnnotationsList(outputList);
      //   setFormFields(outputList);
      // }

      let pdfMaxWidth = 0;

      const dimensions = pdfDoc.getPages().map((page, index) => {
        const adjustedDimension = {
          width: 0,
          height: 0,
          rotation: 0
        };
        let { width: pageWidth, height: pageHeight } = page.getSize();
        const pageRotation = page.getRotation()?.angle;
        adjustedDimension.rotation = pageRotation;

        const { width: cropBoxWidth, height: cropBoxHeight } = page.getCropBox();
        if (cropBoxWidth !== pageWidth || cropBoxHeight !== pageHeight) {
          pageWidth = page.getCropBox()?.width;
          pageHeight = page.getCropBox()?.height;
        }

        if (pageRotation === 0 || pageRotation === 180) {
          // in this case pageWidth and pageHeight should not be swaped
          adjustedDimension.width = pageWidth;
          adjustedDimension.height = pageHeight;
          if (pageWidth > pdfMaxWidth) {
            pdfMaxWidth = pageWidth;
          }
          if (index === 0) {
            firstPageWidth_ref.current = pageWidth;
          }
        } else {
          // here page is rotated 90 or 270 degree, then pageWidth and pageHeight should be swaped
          // adjustedDimension.width = pageHeight;
          // adjustedDimension.height = pageWidth;
          adjustedDimension.width = pageWidth;
          adjustedDimension.height = pageHeight;
          if (pageHeight > pdfMaxWidth) {
            pdfMaxWidth = pageHeight;
          }
          if (index === 0) {
            firstPageWidth_ref.current = pageHeight;
          }
        }
        return adjustedDimension;
      });

      if (pdfMaxWidth > 0) setPdfWidestPageWidth(pdfMaxWidth);

      setPageDimensions(dimensions);

      // console.log('dsvgrtvfd', dimensions, pageDimensions);
    }

    const docUrl = URL.createObjectURL(new Blob([dataBuffer]));

    // const page = pdfDoc.getPages()[0];
    // if(page.getCropBox()?.x!=0 || page.getCropBox()?.y!=0) {
    //   page.setCropBox(0, 0, page.getWidth(), page.getHeight());
    // }
    // if(page.getMediaBox()?.x!=0 || page.getMediaBox()?.y!=0) {
    //   page.setMediaBox(0, 0, page.getWidth(), page.getHeight());
    // }
    // page.drawLine({
    //   start: { x: 0, y: page.getHeight() },
    //   end: { x: 0, y: page.getHeight() - 150 },
    //   thickness: 2,
    //   color: rgb(1, 0, 0),
    //   opacity: 0.75,
    // });
    // page.drawLine({
    //   start: { x: 0, y: page.getHeight() },
    //   end: { x: 150, y: page.getHeight() },
    //   thickness: 2,
    //   color: rgb(1, 0, 0),
    //   opacity: 0.75,
    // });
    // const updatedPdfBytes = await pdfDoc.save();
    // setBufferData(updatedPdfBytes);
    // const modifiedPdfBlob = new Blob([updatedPdfBytes], { type: 'application/pdf' });
    // const newdocUrl = URL.createObjectURL(modifiedPdfBlob);
    // setFile(newdocUrl);

    setFile(docUrl);
  };

  const formFieldNamesHandler = (formFields) => {
    let signature = 1,
      text = 1;
    let object = {};
    formFields.map(
      ({ fieldName, fieldType }) =>
        (object[fieldName] = `${fieldName.split('_')[0]} - ${fieldType === '/Tx' ? text++ : signature++}`)
    );
    setFieldsNameMap(object);
  };
  useEffect(() => {
    if (attachmentArray?.length > 0) {
      const updatedAttachmentArray = attachmentArray.map((obj) => {
        return { ...obj, selectedFileDetail: {} };
      });
      setModifiedAttachmentArray(updatedAttachmentArray);
      setTotalAttach(attachmentArray.length);
    }
  }, [attachmentArray]);

  const loadFileFromUrl = async (documentUrl) => {
    setProgress(0);
    setIsPdfFetching(true);
    try {
      const buffer = await fetch(documentUrl).then(async (res) => {
        const totalSize = Number(res.headers.get('Content-Length'));
        let loaded = 0;

        const reader = res.body.getReader();

        const promiseResult = await new Promise((resolve, reject) => {
          let accumulatedData = new Uint8Array();
          // let isDownloading = null;

          function processResult(result) {
            if (result.done) {
              // clearTimeout(isDownloading); // Clear the timeout when download is complete
              resolve(accumulatedData.buffer);
              return;
            }

            loaded += result.value.length;
            const percentage = Math.round((loaded / totalSize) * 100);
            setProgress(percentage);

            // Reset the timeout to check again after 15ms
            // clearTimeout(isDownloading); // Clear previous timeout
            // isDownloading = setTimeout(function() {
            //   setLoading(false);
            //   reject(new Error("Internet issue"));
            // }, internetFailureThreshold);

            // Append the received chunk to the accumulated data
            accumulatedData = appendUint8Arrays(accumulatedData, result.value);

            // Continue reading the stream
            reader.read().then(processResult);
          }

          reader.read().then(processResult);
        });
        return promiseResult;
      });
      // .catch((err) => {
      //   console.log('ERRRROR', err);
      // });

      if (buffer) {
        bufferDataOrignal_ref.current = buffer;
        setBufferData(buffer);
        await loadFile(buffer);
      } else {
        setLoading(false);
      }
    } catch (error) {
      if ((error.message = 'Internet issue')) {
        toast.error('Please check your internet connection, refresh page!');
      } else {
        toast.error("Document could't be loaded!");
        //change state to show retry button
      }
    } finally {
      setLoading(false);
      setIsPdfFetching(false);
    }
  };

  const fetchDocument = async () => {
    dispatch({ type: DOCUMENT_LOADING });
    setLoading(true);
    try {
      // const response = await getDocumentDetailAnonymous(params.id);
      const response = await documentActions.getDocumentDetailAnonymous(params.id);
      if (response.status === 200) {
        dispatch({
          type: GET_DOCUMENT_DETAIL_SUCCESS,
          payload: {
            document_detail: response.data
          }
        });
        setDocumentUrl(response.data.documentUrl);
        if (response.data.status === 'completed') {
          setIsDocEditMode(false);
          if (localStorage.getItem('just_signed')) {
            localStorage.removeItem('just_signed');
          } else {
            toast.success('Document is already signed');
          }
        } else if (response.data.status === 'pending' && response.data.expiryDate > Date.now()) {
          setIsDocEditMode(true);
        } else {
          setIsDocEditMode(false);
        }
        await loadFileFromUrl(response.data.documentUrl);
        // loadFileFromUrl('../../pdf/largeSize.pdf');
      } else if (response.status === 403 && response.data.message==="session-expired") {
        setLoading(false);
        navigate('/session-expired');
      } else {
        setLoading(false);
        dispatch({ type: GET_DOCUMENT_DETAIL_FAIL });
        dispatch({ type: AUTH_FAILED });
        navigate('/');
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch({ type: GET_DOCUMENT_DETAIL_FAIL });
      dispatch({ type: AUTH_FAILED });
      navigate('/');
    }
  };

  const saveFieldsIndexAndName = (pdfDoc) => {
    const pdfPageCount = pdfDoc.getPageCount();
    const pages = pdfDoc.getPages();

    // let allAnnotations = { array: [], context: {} };
    // "h" for pageNumber and "i" for annotations
    let mainArray = [];
    let temp_array = [];
    for (let h = 0; h < pdfPageCount; h++) {
      let annotations = pages[h].node.Annots();
      if (annotations) {
        for (let i = 0; i < annotations.asArray().length; i++) {
          const annotsDict = annotations.lookup(i).dict;

          let fieldName = '';
          let fieldType = '';
          for (const iter of annotsDict.entries()) {
            // if annotation has field name
            if (iter[0].encodedName === '/T') {
              fieldName = iter[1].value;
              // temp_array.push({ index: i, fieldName: iter[1].value });
            }
            // get field typee
            if (iter[0].encodedName === '/FT') {
              fieldType = iter[1].encodedName;
              // temp_array.push({ index: i, fieldName: iter[1].value });
            }
          }
          // get/save only fields which are annotations
          if (fieldName && fieldType) {
            temp_array.push({ fieldName: fieldName, fieldType: fieldType, pageNumber: h });
          }
        }

        mainArray.push({ [h]: temp_array });
        temp_array = [];
      }
    }
    indexNameArray.current = mainArray;
    return mainArray;
  };

  const allRequiredAttachmentsAttached = () => {
    return modifiedAttachmentArray.every((attachment) => {
      if (attachment?.optional) return true;
      return attachment?.selectedFileDetail && !isObjectEmpty(attachment?.selectedFileDetail);
    });
  };

  const handleUploadDocAndAttachments = async () => {
    setProcessingString('Uploading attachment');
    const uploadingAttachmentsResult = await uploadingAttachments();

    if (uploadingAttachmentsResult) {
      setProcessingString('Uploading document...');
      const { successUploadedAttachmentsMap } = uploadingAttachmentsResult;

      const documentUploaded = await uploadingDocument();
      if (documentUploaded) {
        setProcessingString('Sending...');
        const { filePath, lastModified } = documentUploaded;

        const replyDocumentPayload = { filePath, lastModified, attachments: successUploadedAttachmentsMap };
        // reply to database, or send all cloud files paths to database
        const replyDocumentResult = await documentActions.replyDocument(documentId, replyDocumentPayload);
        if (replyDocumentResult) {
          dispatch({
            type: GET_DOCUMENT_DETAIL_SUCCESS,
            payload: { document_detail: replyDocumentResult.data }
          });
          return true;
        }
      }
    }
    return false;
  };

  const uploadingDocument = async () => {
    const filePathSplit = documentFilePath.split('/');
    if (filePathSplit.length !== 2) return null;
    const documentFileName = filePathSplit[filePathSplit.length - 1];

    //if documentFileName exist only then do axios call
    if (documentFileName !== '') {
      const getFileUploadUrlPayload = { fileName: documentFileName };
      const getFileUploadUrlResponse = await fileActions.getDocumentFileUploadUrl(getFileUploadUrlPayload);
      if (getFileUploadUrlResponse) {
        // making up payload for replyDocument
        const filePath = getFileUploadUrlResponse.filePath;
        const lastModified = Date.now();

        // Create FormData to send the PDF file

        // console.log("fgbrtescfdcdfdsvr 13");
        if (bufferData_ref?.current) {
          // console.log("fgbrtescfdcdfdsvr 14");
          const modifiedPdfBlob = new Blob([bufferData_ref.current], { type: 'application/pdf' });
          // const docUrl = URL.createObjectURL(modifiedPdfBlob);
          // setFile(docUrl);

          // const downloadLink = document.createElement('a');
          // downloadLink.href = URL.createObjectURL(modifiedPdfBlob);
          // downloadLink.download = `${documentDisplayName}.pdf`;
          // downloadLink.click();

          const fileUploadResponse = await fileActions.uploadFileToCloud(
            getFileUploadUrlResponse.accessUrl,
            modifiedPdfBlob
          );
          if (fileUploadResponse) return { filePath, lastModified };
        }
      }
    }
    return null;
  };

  const getFileExtension = (id) => {
    const foundAttachment = modifiedAttachmentArray.find((item) => item.id === id);

    if (foundAttachment?.selectedFileDetail && !isObjectEmpty(foundAttachment.selectedFileDetail)) {
      return foundAttachment.selectedFileDetail.name.split('.').pop();
    } else return 'jpg';
  };

  const uploadingAttachments = async () => {
    // first make array of only attached files
    const successUploadedAttachmentsMap = [];

    if (!attachmentArray.length) return { successUploadedAttachmentsMap };

    const fileNames = [...attachmentArray]
      .sort((a, b) => a.id - b.id)
      .map((a) => `${documentId}-${a.id}.${getFileExtension(a.id)}`);
    const getFileUploadUrlsPayload = { fileNames };

    const attachmentsUploadUrlsArray = await fileActions.getAttachmentsUploadUrls(getFileUploadUrlsPayload);

    if (attachmentsUploadUrlsArray?.length > 0) {
      for (let index = 0; index < attachmentsUploadUrlsArray.length; index++) {
        const uploadUrlObject = attachmentsUploadUrlsArray[index];
        setUploadingAttachNo(index + 1);
        const relatedFileToUpload = modifiedAttachmentArray.find((item) => item.id === uploadUrlObject.id);
        if (!relatedFileToUpload.selectedFileDetail || isObjectEmpty(relatedFileToUpload.selectedFileDetail)) {
          continue;
        }

        const fileUploadResponse = await fileActions.uploadFileToCloud(
          uploadUrlObject.accessUrl,
          relatedFileToUpload.selectedFileDetail
        );
        if (fileUploadResponse) {
          successUploadedAttachmentsMap.push({ id: uploadUrlObject.id, filePath: uploadUrlObject.filePath });
        } else return null;
      }
      return { successUploadedAttachmentsMap };
    }
    return null;
  };

  const isCanvasFieldsUpdated = () => {
    let isAllMandatoryFieldsUpdated = false;
    const unfilledAnnotsArray = [];
    if (!isObjectEmpty(pagesAndAnnots)) {
      for (const [key, annotationsArrayList] of Object.entries(pagesAndAnnots)) {
        for (const annotation of annotationsArrayList) {
          if (!annotation?.isUpdated && !annotation?.optional) {
            annotation.borderColor = annot_errorBorderColor;
            annotation.backgroundColor = annot_errorBackgroundColor;
            unfilledAnnotsArray.push(annotation);
          }
        }
      }
      setPagesAndAnnots((previousValues) => ({ ...previousValues }));
      // Scroll to the page of the first unfilled annotation
      if (unfilledAnnotsArray && unfilledAnnotsArray.length > 0 && unfilledAnnotsArray[0]?.pageNumber) {
        scrollToPage(unfilledAnnotsArray[0].pageNumber, false);
      }
      // Check if all mandatory fields are updated
      isAllMandatoryFieldsUpdated = unfilledAnnotsArray.length === 0;
    }
    return isAllMandatoryFieldsUpdated;
  };

  const handleDoneButton = async () => {
    if (!isCanvasFieldsUpdated()) {
      toast.error('Please fill all required fields!');
    } else {
      if (!allRequiredAttachmentsAttached()) {
        toast.error('Please add required attachments!');

        // download pdf file for testing purpose
        // const pdfDoc = await PDFDocument.load(bufferDataOrignal_ref?.current, { ignoreEncryption: true });
        // const isPdfUpdated = await updatePdfFromCanvas(pdfDoc);
        // if (bufferData_ref?.current && isPdfUpdated) {
        //   // const updatedPdfBytes = await pdfDoc.save();
        //   // bufferData_ref.current = updatedPdfBytes;
        //   // setBufferData(updatedPdfBytes);
        //   const modifiedPdfBlob = new Blob([bufferData_ref.current], { type: 'application/pdf' });
        //   const docUrl = URL.createObjectURL(modifiedPdfBlob);
        //   setFile(docUrl);
        //   // const downloadLink = document.createElement('a');
        //   // downloadLink.href = URL.createObjectURL(modifiedPdfBlob);
        //   // downloadLink.download = `${documentDisplayName}.pdf`;
        //   // downloadLink.click();
        // }
      } else {
        if (window.navigator.onLine) {
          //disable the done button
          setProcessingString('Preparing document...');
          setDoneDisabled(true);
          setOpenProgressModal(true);
          // setLoading(true);

          try {
            const documentValid = await isDocumentValid();

            if (documentValid === 'pending') {
              // updating orignal pdf from currently filled canvas
              const pdfDoc = await PDFDocument.load(bufferDataOrignal_ref?.current, { ignoreEncryption: true });

              const isPdfUpdated = await updatePdfFromCanvas(pdfDoc);
              // console.log("fgbrtescfdcdfdsvr 11", isPdfUpdated);

              // console.log("fgbrtescfdcdfdsvr 13", isPdfUpdated);
              // if(bufferData_ref?.current){
              //   console.log("fgbrtescfdcdfdsvr 14", isPdfUpdated);
              //   const updatedPdfBlob = new Blob([bufferData_ref.current], { type: 'application/pdf' });
              //   const docUrl = URL.createObjectURL(updatedPdfBlob);
              //   setFile(docUrl);

              //   const downloadLink = document.createElement('a');
              //   downloadLink.href = URL.createObjectURL(updatedPdfBlob);
              //   downloadLink.download = `${documentDisplayName}.pdf`;
              //   downloadLink.click();
              // }

              if (isPdfUpdated) {
                const isDocumentSend = await handleUploadDocAndAttachments();
                if (isDocumentSend) {
                  setIsDocEditMode(false);
                  setOpenSuccessModal(true);
                } else {
                  if (!window.navigator.onLine) {
                    toast.error('Please check your internet connection and try again.', { duration: 10000 });
                  } else {
                    toast.error("Failed! document could't be uploaded.", { duration: 10000 });
                  }
                }
              } else {
                toast.error('Could not write to pdf file!, try again');
              }
            } else if (documentValid === 'network-error') {
            } else if (['expired', 'completed', 'withdrawn', 'rejected'].includes(documentValid)) {
              toast.error('Document invalid!');

              window.location.reload();
              // } else if (!documentValid) {
            } else {
              toast.error('Document invalid!');

              // show document in view state only
              handleClearButton();
              setIsDocEditMode(false);
              navigate('/');
            }

            //show pdf on webpage only when all documents submitted successfully.
            // const pdfDoc = await PDFDocument.load(bufferData, { ignoreEncryption: true });
            // const isModifiedPdfReady = await updatePdfFromCanvas(pdfDoc);
          } catch (error) {
            if (error.message === 'timed-out') {
              toast.error('Please check your internet connection and try again.', { duration: 10000 });
            } else {
              toast.error("Failed! document could't be uploaded.", { duration: 10000 });
            }
            // console.log("iuyvbiunnjbvtb error", error?.message ? error?.message : error)
          } finally {
            // console.log("iuyvbiunnjbvtb finally");
            //enable done button
            setDoneDisabled(false);
            setOpenProgressModal(false);
            // setLoading(false);
          }
        } else {
          toast.error('Please check your internet connection and try again.');
        }
      }
    }

    // const isFormFieldsFilled = formFields.length === 0;
    // const isAttachmentsFilled = allRequiredAttachmentsAttached();
    // const disabled = true;

    // if ((!isAttachmentsFilled || !isFormFieldsFilled) && !disabled) {
    //   toast.error('Please fill form fields and attach attachments');
    // } else {
    //   const documentValid = await isDocumentValid();
    //   if (!documentValid) {
    //     toast.error('Document invalid!');
    //     handleClearButton();
    //   } else {
    //     handleUploadDocAndAttachments();
    //   }
    // }
  };

  const isDocumentValid = async () => {
    const response = await documentActions.getDocumentStatus(params.id);
    if (!response) return null;
    else if (response.error === 'network-error') {
      return response.error;
    } else if (response.status) {
      return response.status;
    } else {
      return null;
      // return response?.status === 'pending';
    }
  };

  const handleDocSize = () => {
    // console.log("Calculated resultInPixels:" ,coverScreenWidth());
    const navbarElem = document.getElementsByClassName('navbar');
    const docHeaderElem = document.getElementsByClassName('document-header');
    if (navbarElem[0] && docHeaderElem[0]) {
      const navbarElemHeight = navbarElem[0].clientHeight;
      const docHeaderHeight = docHeaderElem[0].clientHeight;
      const documentElemHeight = window.innerHeight - (navbarElemHeight + docHeaderHeight);
      setDocPaddingTop(navbarElemHeight + docHeaderHeight);
      setDocumentHeight(documentElemHeight);

      // defaultZoomLevel_ref.current = ((window.innerWidth * 0.8)/pdfWidestPageWidth_ref.current) * window.devicePixelRatio;
      // setScale(defaultZoomLevel_ref.current);
    }
  };

  const resetPdfToInitial = () => {
    if (bufferDataOrignal_ref.current) {
      setBufferData(bufferDataOrignal_ref.current);
      bufferData_ref.current = bufferDataOrignal_ref.current;
    } else {
      bufferData_ref.current = null;
      setBufferData(null);
    }
  };

  const handleClearButton = async () => {
    // reset zoom level
    setScale(defaultZoomLevel_ref.current);

    // reset currently showing page to page 1
    // const pageSelectInputElem = document.getElementById('pageSelectInputElem');
    // pageSelectInputElem.value = 1;

    //reset filledFields array to empty
    setFilledFields([]);

    // load pdf file again to get all annotation(if which were deleted so then)
    // loadFileFromUrl('../../pdf/annotationDoc.pdf');

    // loadFileFromUrl(documentUrl);

    clearCanvas();
    clearAttachments();
    resetPdfToInitial();
    // resetPdf();
    // loadFile(orignalBuffer);

    // // load orignal pdf file
    // setBufferData(bufferDataOrignal_ref.current);
    // loadFile(bufferDataOrignal_ref.current);
  };

  const handleDownloadButton = async () => {
    // const updatedPdfBlob = new Blob([bufferData_ref.current], { type: 'application/pdf' });
    const updatedPdfBlob = new Blob([bufferData], { type: 'application/pdf' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(updatedPdfBlob);
    downloadLink.download = `${documentDisplayName}.pdf`;
    downloadLink.click();
  };

  const handleTextFontSize = (fieldProps, newFontSize) => {
    const annotationToUpdate = getAnnotById_refCopy(fieldProps?.id);
    if (
      annotationToUpdate &&
      newFontSize &&
      pageDimensions?.length > 0 &&
      fieldProps?.pageNumber - 1 >= 0 &&
      pageDimensions[fieldProps?.pageNumber - 1]?.height
    ) {
      // change fontSize again to percentage
      newFontSize = newFontSize / pageDimensions[fieldProps?.pageNumber - 1]?.width;
      annotationToUpdate.fontSize = newFontSize;
      setPagesAndAnnots((prevState) => ({
        ...prevState
      }));
    }
  };

  useEffect(() => {
    if (numPages > 0) {
      const tempList = [currentPage - 1, currentPage, currentPage + 1];
      setPreviewPageList([...tempList.filter((tempNum) => tempNum > 0 && tempNum <= numPages)]);
    }
  }, [currentPage, numPages]);

  useEffect(() => {
    window.addEventListener('resize', () => handleDocSize());
    return () => window.removeEventListener('resize', handleDocSize);
  }, [window.innerWidth]);

  useEffect(() => {
    handleDocSize();
  }, [file]);

  useEffect(() => {
    if (params.id) {
      fetchDocument();
    } else {
      navigate('/');
    }
  }, []);

  return (
    <Fragment>
      {!loading && bufferDataOrignal_ref?.current && !isObjectEmpty(pagesAndAnnots) && pageDimensions.length > 0 ? (
        <Fragment>
          {textModalState?.open ? (
            <EditTextModal
              textModalState={textModalState}
              setTextModalState={setTextModalState}
              updateFieldData={updateFieldData}
              handleTextFontSize={handleTextFontSize}
            />
          ) : null}

          {openSignature && clickedItemid_ref.current ? (
            <EditSignModal
              openSignature={openSignature}
              setOpenSignature={setOpenSignature}
              fieldId={clickedItemid_ref.current}
              applySignature={applySignature}
            />
          ) : null}

          {dateModalState?.open ? (
            <EditDateModal
              dateModalState={dateModalState}
              setDateModalState={setDateModalState}
              updateFieldData={updateFieldData}
              handleTextFontSize={handleTextFontSize}
            />
          ) : null}

          <SuccessModal openSuccessModal={openSuccessModal} setOpenSuccessModal={setOpenSuccessModal} />
          <ProgressModal
            openProgressModal={openProgressModal}
            setOpenProgressModal={setOpenProgressModal}
            processingString={processingString}
            uploadingAttachNo={uploadingAttachNo}
            totalAttach={totalAttach}
          />

          <DocumentHeader
            fieldsNameMap={fieldsNameMap}
            handleClearButton={handleClearButton}
            handleDownloadButton={handleDownloadButton}
            doneDisabled={doneDisabled}
            handleDoneButton={handleDoneButton}
            modifiedAttachmentArray={modifiedAttachmentArray}
            setModifiedAttachmentArray={setModifiedAttachmentArray}
            formFields={formFields}
            filledFields={filledFields}
            setTextModalState={setTextModalState}
            setOpenSignature={setOpenSignature}
            setSignatureSeletedField={setSignatureSeletedField}
            annotFieldsStatusArray={annotFieldsStatusArray}
            setCurrentPage={setCurrentPage}
          />

          <div className="document-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <OptionDropDownMenu
                  showClear={documentStatus === 'pending' && expiryDate > Date.now()}
                  handleClearButton={handleClearButton}
                  handleDownloadButton={handleDownloadButton}
                />
              </div>
              <div className="centerAbsoluteHorizontally text-center">
                <PageNumber2 totalPages={numPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                {/* <div className="ml-3">
                  <button type="button" disabled={scale <= 0.5} onClick={decreaseScale}>
                    -
                  </button>
                  <span>{` ${Math.round(scale * 100)}% `}</span>
                  <button type="button" disabled={scale >= 2} onClick={increaseScale}>
                    +
                  </button>
                </div> */}
              </div>

              <div className="text-right">
                <div className="d-flex justify-content-end">
                  {documentStatus === 'pending' && expiryDate > Date.now() && (
                    <>
                      <div className="menuButtonsSetting">
                        {attachmentArray.length > 0 && (
                          <div className="d-flex align-items-center mr-2">
                            <span className="dropdown-helper-text mr-2">Attachments</span>
                            <AttachmentDropdownMenu
                              attachmentArray={attachmentArray}
                              modifiedAttachmentArray={modifiedAttachmentArray}
                              setModifiedAttachmentArray={setModifiedAttachmentArray}
                            />
                          </div>
                        )}
                        <div className="d-flex align-items-center mr-2">
                          <span className="dropdown-helper-text text-nowrap mr-2">Required fields</span>
                          <FieldsDropdownMenu
                            annotFieldsStatusArray={annotFieldsStatusArray}
                            // currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                          />
                        </div>
                      </div>
                      {/* <Button
                        className="theme-button-primary rounded-pill"
                        type="button"
                        variant="contained"
                        disabled={doneDisabled}
                        onClick={() => handleDoneButton()}>
                        <span className="done-btn-text">Done</span>
                      </Button> */}
                      <button
                        className="header-button"
                        style={{ width: '100px', height: '38px' }}
                        disabled={doneDisabled}
                        onClick={() => handleDoneButton()}>
                        <div className="d-flex align-items-center">
                          <span className="">Done</span>
                          {doneDisabled ? (
                            <div
                              className="spinner-border text-light ml-2"
                              style={{ width: '1.2rem', height: '1.2rem' }}
                              role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                        </div>
                      </button>
                    </>
                  )}

                  {documentStatus !== 'pending' && <DocumentStatus status={documentStatus} />}
                  {documentStatus === 'pending' && expiryDate < Date.now() && <DocumentStatus status={'expired'} />}
                </div>
              </div>
            </div>
          </div>

          <div className="document-view" style={{ paddingTop: docPaddingTop, minWidth: '280px' }}>
            <div
              className="viewbox"
              // style={{ maxHeight: documentHeight, width: pdfDocWidth + docScrollBarSize, position: 'relative' }}
              style={{
                maxHeight: documentHeight,
                position: 'relative'
              }}>
              <div className="pdf scrollElement">
                {!isObjectEmpty(pagesAndAnnots) && pageDimensions.length > 0 && (
                  <Document
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<Loader color="primary" />}
                    error="Could not load pdf, Please refresh the page">
                    {!isDocLoading &&
                      Array.from({ length: numPages }, (_, index_page) => (
                        <div
                          key={`stage-${index_page + 1}`}
                          className="pageContext"
                          data-page-number={index_page + 1}
                          style={{
                            display: 'flex',
                            // display: 'inline-block',
                            justifyContent: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '6px',
                            backgroundColor: '#ffffff',
                            width: `${pageDimensions[index_page]?.width * scale}px`,
                            height: `${pageDimensions[index_page]?.height * scale}px`
                          }}>
                          {/* {console.log("FGHFGHFGH: ", pageDimensions[index_page].width * scale * 0.2)} */}
                          {previewPageList.includes(index_page + 1) ? (
                            <Page
                              key={index_page + 1}
                              pageNumber={index_page + 1}
                              renderAnnotationLayer={false}
                              renderTextLayer={false}
                              loading={<Loader color="primary" />}
                              scale={scale}
                              // onLoadSuccess={handlePageLoadSuccess}
                              width={pageDimensions[index_page]?.width}
                              // width={!pageDimensions[index_page]?.rotation ? pageDimensions[index_page]?.width : pageDimensions[index_page]?.height}
                            >
                              {/* draw canvas only on pages which have any of annotation */}
                              {/* {console.log("gtrfdvdtgb ", index_page, pageDimensions[index_page]?.rotation)} */}
                              {index_page > -1 && pagesAndAnnots[index_page + 1]?.length > 0 && isDocEditMode ? (
                                <Stage
                                  width={pageDimensions[index_page]?.width * scale}
                                  height={pageDimensions[index_page]?.height * scale}
                                  // width={!pageDimensions[index_page]?.rotation ? pageDimensions[index_page]?.width * scale :pageDimensions[index_page]?.height * scale}
                                  // height={!pageDimensions[index_page]?.rotation ? pageDimensions[index_page]?.height * scale : pageDimensions[index_page]?.width * scale}

                                  style={{
                                    position: 'absolute',
                                    zIndex: '3',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                  }}
                                  // pixelRatio={defaultZoomLevel_ref.current/scale}
                                  // ref={stageRef}
                                >
                                  <Layer ref={layerRef}>
                                    <CustomCanvasText
                                      pagesAndAnnots={pagesAndAnnots}
                                      pageDimensions={pageDimensions}
                                      pagesAndSignAnnots={pagesAndSignAnnots}
                                      index_page={index_page}
                                      scale={scale}
                                      customLineHeight={customLineHeight}
                                      fontSizeAdjustFactor={fontSizeAdjustFactor}
                                    />

                                    {pagesAndAnnots?.[index_page + 1]?.map((value, index_rect) => {
                                      if (isCoordinatesExist(value)) {
                                        // console.log('dfgtrefv : ', pageDimensions[index_page]);
                                        return (
                                          <Rect
                                            key={index_rect}
                                            x={value?.x * pageDimensions[index_page]?.width * scale}
                                            y={value?.y * pageDimensions[index_page]?.height * scale}
                                            width={value?.width * pageDimensions[index_page]?.width * scale}
                                            height={
                                              value?.height * value?.width * pageDimensions[index_page]?.width * scale
                                            }
                                            // fill={pagesAndAnnots[index_page + 1][index_rect]?.hideColor ? undefined : "#00ceff3d"}
                                            // fill={pagesAndAnnots[index_page + 1][index_rect]?.backgroundColor}
                                            onClick={(e) => handleClick(e, index_page, index_rect)}
                                            onTouchStart={(e) => handleClick(e, index_page, index_rect)}
                                            // stroke="black" // Border color
                                            stroke={!value?.isUpdated || value?.isHoverColor ? 'black' : 'transparent'} // Border color
                                            strokeWidth={0.3} // Border width
                                            // globalCompositeOperation="source-over"
                                            globalCompositeOperation="multiply"
                                            // fill={isRectHovered ? 'red' : 'green'}
                                            fill={
                                              value?.isHoverColor && value?.isUpdated
                                                ? annotHoverColor
                                                : pagesAndAnnots[index_page + 1][index_rect]?.backgroundColor
                                            }
                                            onMouseEnter={() => {
                                              if (value?.isUpdated) {
                                                handleMouseEnterOnAnnot(index_page, index_rect);
                                              }
                                            }}
                                            onMouseLeave={() => {
                                              if (value?.isUpdated) {
                                                handleMouseLeaveOnAnnot(index_page, index_rect);
                                              }
                                            }}
                                          />
                                        );
                                      }
                                    })}
                                  </Layer>
                                </Stage>
                              ) : null}
                            </Page>
                          ) : null}
                        </div>
                      ))}
                  </Document>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}

      {loading ? (
        <Fragment>
          <div className="document-header-loader">
            <Loader color="primary" />
            {isPdfFetching ? <div>Loading pdf: {progress}%</div> : null}
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default ViewDocument2;

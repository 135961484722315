/* eslint-disable */
import React, { Fragment } from 'react';
import { Group, Image, Text } from 'react-konva';
import PropTypes from 'prop-types';
import { isCoordinatesExist, isObjectEmpty } from '../../utils/utilityFunctions';
import textIcon from '../../assets/images/annotationIcons/text.svg';
import multilineIcon from '../../assets/images/annotationIcons/multi-line.svg';
import dateIcon from '../../assets/images/annotationIcons/date.svg';
import signatureIcon from '../../assets/images/annotationIcons/signature.svg';

const CustomCanvasText = ({
  pagesAndAnnots,
  pageDimensions,
  pagesAndSignAnnots,
  index_page,
  scale,
  customLineHeight,
  fontSizeAdjustFactor
}) => {
  const imageSources = {
    text: textIcon,
    multiline: multilineIcon,
    date: dateIcon,
    signature: signatureIcon
  };
  const imageObjs = {};
  Object.keys(imageSources).forEach((key) => {
    const imageObj = new window.Image();
    // imageObj.onload = () => {
    //     console.log(`Image ${key} loaded`);
    // };
    imageObj.src = imageSources[key];
    imageObjs[key] = imageObj;
  });

  // const iconWidth = 20;
  const iconWidth = (fontSize, pageWidth) => {
    if (fontSize > 0 && pageWidth > 0) {

      // here iconWidth depend on pageWidth
      const iconWidth = pageWidth * 0.03;
      // console.log("dsfrevdsf ", scale, iconWidth, pageWidth);
      return iconWidth;

      // below code depend iconWidth on fontSize of that specific field
      // const calculatedFontSize = fontSize * pageWidth * fontSizeAdjustFactor;
      // const iconWidth = calculatedFontSize * 0.75;
      // console.log("dsfrevdsf ", fontSize, pageWidth, scale, fontSizeAdjustFactor, calculatedFontSize, iconWidth);
      // return iconWidth;
    }
    return 20; //default iconWidth
  };

  const textComp = (fieldProps) => {
    const textCoordinates = {};

    if (!isObjectEmpty(fieldProps)) {
      const { x, y, fontSize, type, isUpdated, width, height } = fieldProps;
      const { width: pageWidth, height: pageHeight } = pageDimensions[index_page];

      textCoordinates.x = (iconWidth(fontSize, pageWidth) + x * pageWidth) * scale;
      textCoordinates.y = y * pageHeight * scale;
      textCoordinates.fontSize = fontSize * pageWidth * scale * fontSizeAdjustFactor;

      if (!isUpdated) {
        if (type === 'signature') {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const calculatedFontSize = fontSize * pageWidth * scale * fontSizeAdjustFactor;
          ctx.font = `${calculatedFontSize}px Courier`;
          const textWidth = ctx.measureText('Sign').width;
          if (textWidth > (width * pageWidth - iconWidth(fontSize, pageWidth)) * scale) {
            textCoordinates.width = (width * pageWidth - iconWidth(fontSize, pageWidth)) * scale * 0.9;
          }
          canvas.remove();
        } else {
          if (type === 'multiline') {
            textCoordinates.height = height * width * pageWidth * scale;
          }
          textCoordinates.width = (width * pageWidth - iconWidth(fontSize, pageWidth)) * scale;
        }
      } else {
        if (type === 'multiline') {
          // textCoordinates.width = width * pageWidth * scale;
          textCoordinates.width = undefined;
          textCoordinates.height = undefined;
        } else {
          textCoordinates.width = undefined;
          textCoordinates.height = height * width * pageWidth * scale;
        }
      }
    }
    return textCoordinates;
  };

  const iconComp = (fieldProps) => {
    const iconProps = {};
    if (!isObjectEmpty(fieldProps)) {
      const { type, fontSize, x, y } = fieldProps;
      const { width: pageWidth, height: pageHeight } = pageDimensions[index_page];
      const calculatedFontSize = fontSize * pageWidth * scale * fontSizeAdjustFactor;
      iconProps.src = imageObjs[type];
      iconProps.width = iconWidth(fontSize, pageWidth) * scale;
      // iconProps.width = calculatedFontSize * 0.75;
      // iconProps.y = (y * pageHeight + (fontSize * pageWidth * fontSizeAdjustFactor)*0.15) * scale;
      iconProps.x = x * pageWidth * scale;
      // iconProps.y = y * pageHeight * scale;
      iconProps.y = y * pageHeight * scale + calculatedFontSize / 2 - (iconWidth(fontSize, pageWidth) * scale) / 2;
    }

    return iconProps;
  };

  const groupComp = (fieldProps) => {
    const groupCoordinates = { x: 0, y: 0 };

    if (!isObjectEmpty(fieldProps)) {
      const { fontSize, type, isUpdated, width, height } = fieldProps;
      const { width: pageWidth, height: pageHeight } = pageDimensions[index_page];
      const calculatedFontSize = fontSize * pageWidth * scale * fontSizeAdjustFactor;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.font = `${calculatedFontSize}px Courier`;
      if (!isUpdated) {
        // set vertically and horizontally center for "signature"
        if (type === 'signature') {
          const textWidth = ctx.measureText('Sign').width;

          groupCoordinates.x = (width * pageWidth * scale) / 2 - textWidth / 2 - iconWidth(fontSize, pageWidth) / 2;
          groupCoordinates.x = Math.max(0, groupCoordinates.x);
          // groupCoordinates.y = (height * width * pageWidth * scale) / 2 - iconWidth / 2;
          groupCoordinates.y = ((height * width * pageWidth) / 2) * scale - calculatedFontSize / 2;

          if (textWidth > (width * pageWidth - iconWidth(fontSize, pageWidth)) * scale * 0.9) {
            groupCoordinates.x =
              ((width * pageWidth * scale) / 2 -
                ((width * pageWidth - iconWidth(fontSize, pageWidth)) * scale * 0.9) / 2 -
                (iconWidth(fontSize, pageWidth) * scale) / 2) *
              1;
            // groupCoordinates.x = (width * pageWidth - iconWidth) * scale * 0.9;
            // console.log("dsfgfrv ", groupCoordinates.x);
          }

          canvas.remove();
        } else if (type === 'multiline') {
          groupCoordinates.x = 0;
          groupCoordinates.y = 0;
        } else {
          // only vertically center
          groupCoordinates.x = 0;
          groupCoordinates.y = ((height * width * pageWidth) / 2) * scale - calculatedFontSize / 2;
        }
      } else {
        groupCoordinates.x = -iconWidth(fontSize, pageWidth) * scale;
        groupCoordinates.y = 0;
      }
    }
    return groupCoordinates;
  };

  return (
    <>
      {pagesAndAnnots?.[index_page + 1]?.map((value, index_field) => {
        if (isCoordinatesExist(value)) {
          return (
            <Fragment key={`textAndImageOuterElem_${index_field}`}>
              {value?.type === 'text' ||
              value?.type === 'multiline' ||
              value?.type === 'date' ||
              (value?.type === 'signature' && !value?.isUpdated) ? (
                <>
                  <Group x={groupComp(value)?.x} y={groupComp(value)?.y}>
                    {!value?.isUpdated ? (
                      <Image
                        key={`image_${index_field}`}
                        image={iconComp(value)?.src}
                        x={iconComp(value)?.x}
                        // y={value?.y * pageDimensions[index_page]?.height * scale}
                        // y={value?.y * pageDimensions[index_page]?.height * scale}
                        y={iconComp(value)?.y}
                        // width={value?.width * pageDimensions[index_page]?.width * scale}
                        // height={value?.height * value?.width * pageDimensions[index_page]?.width * scale}
                        // width={iconWidth * scale}
                        // height={iconWidth * scale}
                        width={iconComp(value)?.width}
                        height={iconComp(value)?.width}
                        // fill={'red'}
                      />
                    ) : null}
                    <Text
                      key={`text_${index_field}`}
                      id={value?.id || index_field}
                      type="text"
                      x={textComp(value)?.x}
                      y={textComp(value)?.y}
                      width={textComp(value)?.width}
                      height={textComp(value)?.height}
                      text={value?.textValue || 'Sign'}
                      fontFamily="Courier"
                      fontSize={textComp(value)?.fontSize}
                      lineHeight={value?.type === 'multiline' ? customLineHeight : 1}
                      fill={value?.textColor || 'black'}
                      // align={value?.type === 'signature' ? 'center' : 'left'}
                      verticalAlign={value?.type === 'multiline' ? 'top' : 'middle'}
                      // ellipsis={value?.type === 'signature' ? true : undefined}
                      ellipsis={!value?.isUpdated ? true : false}
                      // wrap={!value?.isUpdated ? 'none' : 'wrap'}
                      wrap={'none'}
                      // whiteSpace="nowrap"
                      // wrap={value?.type === 'multiline' ? 'wrap' : 'none'}
                      // padding={10} // Add padding around the text
                      // fontStyle="italic" // Set the text style to italic
                      rotation={value?.rotation || 0}
                      opacity={value?.opacity || 1}
                    />
                  </Group>
                </>
              ) : null}

              {value?.signatureUri &&
              value?.isUpdated &&
              !isObjectEmpty(pagesAndSignAnnots[index_page + 1][index_field]) ? (
                <Image
                  image={value?.signatureUri}
                  x={pagesAndSignAnnots[index_page + 1][index_field]?.x}
                  y={pagesAndSignAnnots[index_page + 1][index_field]?.y}
                  width={pagesAndSignAnnots[index_page + 1][index_field]?.width}
                  height={pagesAndSignAnnots[index_page + 1][index_field]?.height}
                  // key={index_field + 10}
                />
              ) : null}
            </Fragment>
          );
        }
      })}
    </>
  );
};

CustomCanvasText.propTypes = {
  pagesAndAnnots: PropTypes.object,
  pageDimensions: PropTypes.array,
  pagesAndSignAnnots: PropTypes.object,
  index_page: PropTypes.number,
  scale: PropTypes.number,
  customLineHeight: PropTypes.number,
  fontSizeAdjustFactor: PropTypes.number
};

export default React.memo(CustomCanvasText);

/* eslint-disable no-unused-vars */
import { Backdrop, Box, Button, Fade, Modal, Slider, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Layer, Line, Stage, Text } from 'react-konva';
import keyboardIcon from '../../assets/images/keyboard.svg';
import penNibIcon from '../../assets/images/pen-nib.svg';
import penNibInactive from '../../assets/images/pen-nib-inactive.svg';
import keyboardInactive from '../../assets/images/keyboard-inactive.svg';
import helper_functions from '../../utils/helper_functions';
import _ from 'lodash';
import { canvasPreview } from './canvasPreview';
import SignatureTextField from '../../components/form-fields/SignatureTextField';
import FontSelection from '../../components/document-components/FontSelection';

const EditSignModal = ({ openSignature, setOpenSignature, fieldId, applySignature }) => {
  const [selectedTab, setSelectedTab] = useState('SIGN');
  const tabListHandleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [size, setSize] = useState(5);
  const [color, setColor] = useState('#00000');
  const [colorShow, setColorShow] = useState(false);
  const [tool, setTool] = useState('pen');
  const [date, setDate] = useState(null);
  const [lines, setLines] = useState([]);
  const [drawn, setDrawn] = useState(false);
  const isDrawing = useRef(false);
  const stageRef = useRef();
  const textRef = useRef();
  const [arr_line, setArr_line] = useState([]);
  const [xCords, setXCords] = useState(0);
  const [yCords, setYCords] = useState(0);
  const [canvasWidth, setWidth] = useState(0);
  const [canvasHeight, setHeight] = useState(0);

  const [signatureText, setSignatureText] = useState('');
  const [font, setFont] = useState('Arial');

  // ************************************ \\
  const [imgSrc, setImgSrc] = useState('');

  const previewCanvasRef = useRef(null);
  const textCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState({});

  // console.log('adsvbtrnhy ', fieldId);

  // ***************************** \\

  useEffect(() => {}, [canvasWidth, canvasHeight]);
  const handleClose = () => {
    setOpenSignature(false);
    // setActive('');
    setLines([]);
    setArr_line([]);
    setCompletedCrop({});
    setDate(null);
    setSignatureText('');
  };

  useEffect(() => {
    const calculateWidthRatio = window.innerWidth < 860 ? 95 : 55;
    const widthTemp = (window.innerWidth / 100) * calculateWidthRatio - 50;
    setWidth(widthTemp);
    setHeight(window.innerWidth < 860 ? (widthTemp > 400 ? 400 : widthTemp) : (window.innerHeight / 100) * 60 - 240);

    setLines([]);
    return () => {
      setArr_line([]);
      setLines([]);
    };
  }, []);

  useEffect(() => {
    if (textRef.current) {
      setXCords(canvasWidth - textRef.current.width());
      setYCords(canvasHeight - textRef.current.height());
    }
  }, [date]);

  function downloadImage(uri, name) {
    // handleClose();
    // onSignatureApply(uri);
    // console.log(name, uri);
    // applySignature(uri);
    applySignature(fieldId, uri);
    handleClose();
    // window.open(uri, '');
    // setOpenSignature(false);
  }
  const dateSet = () => {
    if (!date) {
      let text = new Date();
      let hrs, mins, secs, format;
      if (text.getHours() > 12) {
        hrs = text.getHours() - 12;
        format = 'pm';
      } else {
        hrs = text.getHours();
        format = 'am';
      }
      mins = text.getMinutes();
      secs = text.getSeconds();
      if (hrs < 10) hrs = '0' + hrs;
      if (mins < 10) mins = '0' + mins;
      if (secs < 10) secs = '0' + secs;
      setDate(
        text.getDate() +
          '/' +
          (text.getMonth() + 1) +
          '/' +
          text.getFullYear() +
          ' ' +
          hrs +
          ':' +
          mins +
          ':' +
          secs +
          ' ' +
          format
      );
    } else setDate(null);
  };

  const Apply = async () => {
    if (selectedTab === 'KEYBOARD') {
      // console.log(signatureText);
      if (signatureText.length && textCanvasRef.current) {
        const canvas = textCanvasRef.current;
        const ctx = canvas.getContext('2d');
        // console.log(canvas, 'canvas');

        // ctx.clearRect(0, 0, canvas.width, canvas.height);
        // // Set up canvas context properties
        const numberOfLetters = signatureText.length;
        // console.log(numberOfLetters);
        let ratio = 0.9;
        if (numberOfLetters < 15) ratio = 1;
        if (numberOfLetters < 12) ratio = 1.1;
        if (numberOfLetters < 8) ratio = 1.15;
        if (numberOfLetters < 5) ratio = 1.2;

        canvas.width = 480 * numberOfLetters * ratio;
        canvas.height = 1000;
        ctx.font = `${850}px ${font}`;
        // ctx.fillStyle = "red";
        ctx.textAlign = 'center';

        // Write text onto the canvas
        ctx.fillText(signatureText, canvas.width / 2, (canvas.height + 400) / 2, canvas.width);
        // console.log(ctx);
        const dataUrl = await canvas.toDataURL({
          quality: 1,
          pixelRatio: 5
        });
        // console.log('asdvbtrgbg signModal', fieldId, dataUrl);

        setImgSrc(dataUrl);

        applySignature(fieldId, dataUrl);

        handleClose();
      }
    }
    if (selectedTab === 'SIGN') {
      if (arr_line.length && stageRef.current) {
        // Find the max and min x and y values
        const maxX = _.maxBy(arr_line, 'x');
        const maxY = _.maxBy(arr_line, 'y');
        const minX = _.minBy(arr_line, 'x');
        const minY = _.minBy(arr_line, 'y');

        // Get the image data from the canvas
        const image_data = await stageRef.current.toDataURL({
          // mimeType: "image/jpeg",
          quality: 1,
          pixelRatio: 5
        });
        setImgSrc(image_data);

        // Calculate the crop coordinates and dimensions
        let cropProps;
        if (date) {
          cropProps = {
            unit: 'px',
            x: (minX.x - 5) * 5,
            y: (minY.y - 5) * 5,
            width: (canvasWidth - minX.x + 5) * 5,
            height: (canvasHeight - minY.y + 5) * 5
          };
        } else {
          cropProps = {
            unit: 'px',
            x: (minX.x - 5) * 5,
            y: (minY.y - 5) * 5,
            width: (maxX.x - minX.x + 10) * 5,
            height: (maxY.y - minY.y + 10) * 5
          };
        }

        // Set the crop properties
        setCompletedCrop(cropProps);
        setDrawn(!drawn);
      }
    }
  };

  helper_functions.useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // console.log(imgRef);
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, downloadImage);
      }
    },
    100,
    [completedCrop]
  );
  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y], colors: color, stroke: size }]);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing.current) return;

    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    if (point) {
      setArr_line((prev) => [...prev, point]);

      const lastLine = lines[lines.length - 1];
      if (lastLine) {
        lastLine.points = [...lastLine.points, point.x, point.y];
        setLines((prev) => [...prev.slice(0, -1), lastLine]);
      }
    }
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };
  // const [completedCrop, setCompletedCrop] = useState({});

  // const style = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: '70%',
  //   minHeight: '200px',
  //   height: 'auto',
  //   bgcolor: 'background.paper',
  //   border: '0px',
  //   borderRadius: '1rem',
  //   boxShadow: 24,
  //   p: 4
  // };

  return (
    <Fragment>
      <Modal
        open={openSignature}
        onClose={() => {
          handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 800
        }}>
        <Fade in={openSignature}>
          <div className="signature_modal">
            <Box className="signature_modal-header">
              <Tabs value={selectedTab} onChange={tabListHandleChange} aria-label="secondary tabs example" centered>
                <Tab
                  icon={<img src={selectedTab === 'KEYBOARD' ? keyboardIcon : keyboardInactive} />}
                  label={<span className="tab-text">Type Signature</span>}
                  style={tabStyle}
                  value="KEYBOARD"
                />
                <Tab
                  icon={<img src={selectedTab === 'SIGN' ? penNibIcon : penNibInactive} />}
                  label={<span className="tab-text">Draw Signature</span>}
                  style={tabStyle}
                  value="SIGN"
                />
              </Tabs>
            </Box>
            <Box className="signature_modal-sub-header stroke">
              {selectedTab === 'KEYBOARD' && <FontSelection font={font} setFont={setFont} />}
              {selectedTab === 'SIGN' && (
                <Slider size="small" value={size} min={1} max={10} onChange={(e, value) => setSize(value)} />
              )}
            </Box>
            <Box className="signature_modal-body">
              {selectedTab === 'KEYBOARD' && (
                <Fragment>
                  <SignatureTextField
                    font={font}
                    signatureText={signatureText}
                    setSignatureText={setSignatureText}
                    maxLength={25}
                  />
                  <Stage className="centerline centerline-text m-0 p-0" width={canvasWidth} height={canvasHeight}>
                    <Layer>
                      <Line
                        x={0}
                        y={canvasHeight / 2}
                        dash={[5, 5]} // sets the dash pattern to a dotted line
                        points={[0, 0, canvasWidth, 0]} // sets the line position
                        stroke="#000" // sets the line color
                        strokeWidth={1} // sets the line width
                        opacity={0.5} // sets the line opacity
                      />
                    </Layer>
                  </Stage>
                </Fragment>
              )}
              {selectedTab === 'SIGN' && (
                <Fragment>
                  {!!completedCrop && (
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        display: 'none',
                        border: '1px solid black',
                        objectFit: 'contain',
                        width: completedCrop.width,
                        height: completedCrop.height
                      }}
                    />
                  )}
                  {<img className="d-none" ref={imgRef} alt="Crop me" src={imgSrc} />}
                  <Stage className="centerline m-0 p-0" width={canvasWidth} height={canvasHeight}>
                    <Layer>
                      <Line
                        x={0}
                        y={canvasHeight / 2}
                        dash={[5, 5]} // sets the dash pattern to a dotted line
                        points={[0, 0, canvasWidth, 0]} // sets the line position
                        stroke="#000" // sets the line color
                        strokeWidth={1} // sets the line width
                        opacity={0.5} // sets the line opacity
                      />
                    </Layer>
                  </Stage>
                  <Stage
                    className=" m-0 p-0 signature-area"
                    ref={stageRef}
                    width={canvasWidth}
                    height={canvasHeight}
                    onMouseDown={handleMouseDown}
                    onMousemove={handleMouseMove}
                    onMouseup={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchMove={handleMouseMove}
                    onTouchEnd={handleMouseUp}>
                    <Layer>
                      {/* {!drawn &&
                  <Line
                    x={50}
                    y={100}
                    dash={[5, 5]} // sets the dash pattern to a dotted line
                    points={[0, 0, window.innerWidth, 0]} // sets the line position
                    stroke="#000" // sets the line color
                    strokeWidth={1} // sets the line width
                    opacity={0.5} // sets the line opacity
                  />
                } */}
                      {lines.map((line, i) => (
                        <Line
                          key={i}
                          points={line.points}
                          stroke={line.colors}
                          strokeWidth={line.stroke}
                          tension={0.5}
                          lineCap="round"
                          lineJoin="round"
                          globalCompositeOperation={line.tool === 'eraser' ? 'destination-out' : 'source-over'}
                        />
                      ))}
                      {/* <Text ref={textRef} fontFamily={'datefont'} fontSize={5} text={date} x={xCords} y={yCords} /> */}
                    </Layer>
                  </Stage>
                </Fragment>
              )}
            </Box>
            <div className="d-flex justify-content-end mx-5">
              <button
                className="clear-button"
                onClick={() => {
                  setArr_line([]);
                  setLines([]);
                  setDate(null);
                  setSignatureText('');
                }}>
                Clear
              </button>
            </div>
            <Box className="signature_modal-footer">
              {/* {selectedTab === 'SIGN' && ( */}
              <div className="sign-modal-action">
                <button
                  className="btn theme-button-primary clear-btn rounded-pill px-4"
                  onClick={() => {
                    setArr_line([]);
                    setLines([]);
                    setDate(null);
                    setSignatureText('');
                  }}>
                  Clear
                </button>
                <button className="close-btn rounded-pill px-4" onClick={handleClose}>
                  Close
                </button>
                <button className="btn theme-button-primary rounded-pill px-4" onClick={() => Apply()}>
                  Apply
                </button>

                {/* <button
                    className="btn theme-button-primary rounded-pill px-4"
                    onClick={() => {
                      dateSet();
                    }}>
                    Set Date
                  </button> */}
              </div>
              {/* )} */}
            </Box>
            <canvas
              ref={textCanvasRef}
              style={{
                display: 'none',
                position: 'absolute',
                right: 10,
                bottom: 60,
                zIndex: 999,
                width: 'auto',
                height: 100,
                border: '1px solid black',
                objectFit: 'contain'
              }}
            />
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
};

const tabStyle = { textTransform: 'initial', fontSize: '16px', fontWeight: 'bold' };

EditSignModal.propTypes = {
  openSignature: PropTypes.bool,
  setOpenSignature: PropTypes.func,
  fieldId: PropTypes.string,
  applySignature: PropTypes.func
};

export default EditSignModal;

import 'bootstrap/dist/css/bootstrap.min.css';
import { Fragment } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import ToastNotification from './components/app-components/ToastNotification';
import AuthenticatedLayout from './layout/AuthenticatedLayout';
import AccessCodeValidation from './pages/auth-pages/AccessCodeValidation';
import Logout from './pages/auth-pages/Logout';
import NotFound from './pages/auth-pages/NotFound';
// import SignIn from './pages/auth-pages/SignIn';
// import SignUp from './pages/auth-pages/SignUp';
// import ViewDocument from './pages/document-pages/ViewDocument';
import ViewDocument2 from './pages/document-pages/ViewDocument2';
import HomePage2 from './pages/document-pages/HomePage2';
import SessionExpired from './pages/auth-pages/SessionExpired';

function App() {
  return (
    <Fragment>
      <ToastNotification />
      <Routes>
        {/* <Route path="/login" element={<SignIn />} />
        <Route path="/register" element={<SignUp />} /> */}
        <Route path="/validate" element={<AccessCodeValidation />} />
        {/* <Route path="/" element={<NotFound />} /> */}
        <Route path="/session-expired" element={<SessionExpired />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/" element={<HomePage2 />} />

        <Route path="/documents" element={<AuthenticatedLayout />}>
          <Route index element={<NotFound />} />
          {/* <Route path="view/:id" element={<ViewDocument />} /> */}
          <Route path="view/:id" element={<ViewDocument2 />} />
          <Route path="*" element={<Navigate to="/documents" />} />
        </Route>
      </Routes>
    </Fragment>
  );
}
export default App;

import {
  AUTH_SIGNUP,
  AUTH_LOGIN,
  AUTH_ANONYMOUS_SUCCESS,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_FAILED
} from '../action-types';

const initialState = {
  authToken: null,
  anonymousToken: null,
  refreshAnonymousToken: null,
  userData: null,
  isAnonymous: true,
  isLogin: false
};

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case AUTH_SIGNUP:
      return state;
    case AUTH_LOGIN:
      return {
        ...initialState,
        authToken: payload.authToken,
        userData: payload.userData,
        isLogin: true,
        isAnonymous: false
      };
    case AUTH_ANONYMOUS_SUCCESS:
      return {
        ...initialState,
        anonymousToken: payload.anonymousToken,
        refreshAnonymousToken: payload.refreshAnonymousToken,
        userData: payload.userData,
        isLogin: true,
        isAnonymous: true
      };
    case AUTH_FAILED:
    case AUTH_LOGOUT:
      return initialState;
    case AUTH_LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
};

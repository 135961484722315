const isNumber = (value) => {
  return typeof value === 'number' && Number.isFinite(value);
};

export const isCoordinatesExist = (obj) => {
  if (isNumber(obj.x) && isNumber(obj.y) && isNumber(obj.width) && isNumber(obj.height)) {
    return true;
  } else false;
};

export const deepCopyObject = (obj) => {
  if (obj === null || typeof obj !== 'object') return obj;
  if (Array.isArray(obj)) return obj.map((item) => deepCopyObject(item));
  const copiedObject = {};
  Object.keys(obj).forEach((key) => (copiedObject[key] = deepCopyObject(obj[key])));
  return copiedObject;
};

export const isObjectEmpty = (obj) => {
  for (let z in obj) {
    return false;
  }
  return true;
};

export const truncateStringMiddle = (str, maxCharacters) => {
  if (typeof str === 'string' && typeof maxCharacters === 'number' && str?.length > 0 && maxCharacters > 0) {
    if (str?.length > maxCharacters) {
      const firstPart = str.substring(0, maxCharacters / 2 - 1);
      const lastPart = str.substring(str.length - maxCharacters / 2 + 2);
      return firstPart + '...' + lastPart;
    }
    return str;
  }
  return null;
};

export const truncateStringEnd = (str, maxCharacters) => {
  if (typeof str === 'string' && typeof maxCharacters === 'number' && str?.length > 0 && maxCharacters > 0) {
    return str?.length > maxCharacters ? str.substring(0, maxCharacters) + '...' : str;
  }
  return null;
};

export const appendUint8Arrays = (arr1, arr2) => {
  const result = new Uint8Array(arr1.length + arr2.length);
  result.set(arr1, 0);
  result.set(arr2, arr1.length);
  return result;
};

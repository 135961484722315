/* eslint-disable */
import { Box, Button, Modal } from '@mui/material';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { validateDateModalSchema } from '../../schemas/validateTextModalSchema';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const EditDateModal = ({ dateModalState, setDateModalState, updateFieldData, handleTextFontSize }) => {

  const { values, errors, touched, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: { text: dayjs(dateModalState?.textValue).isValid() ? dayjs(dateModalState?.textValue).format('MMM DD, YYYY') : dayjs(new Date).format('MMM DD, YYYY')},
    validationSchema: validateDateModalSchema,
    onSubmit: async (values, { resetForm }) => {
      onSubmitHandler(values);
      resetForm({ values: { text: '' } });
    },
  });

  const [fontSize, setFontSize] = useState(dateModalState?.fontSize || 16);
  const onSubmitHandler = async (values) => {
    if(dayjs(values?.text).isValid()){
      setDateModalState({
        ...dateModalState,
        open: false,
        textValue: dayjs(values.text).format('MMM DD, YYYY')
      });
      await updateFieldData({
        id: dateModalState.id,
        textValue: dayjs(values.text).format('MMM DD, YYYY')
      });
    }
    else{
      setValues((prevValues) => ({
        ...prevValues,
        text: "",
      }));
    }
  };


  const handleClose = () => {
    setDateModalState({
      ...dateModalState,
      open: false
    });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '500px',
    minHeight: '200px',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '0px',
    borderRadius: '1rem',
    boxShadow: 24,
    p: 4
  };

  const handleFontSizeClick = (change)=>{
    const fieldProps = {
      id: dateModalState?.id || 0,
      pageNumber: dateModalState?.pageNumber || 1
    }
    const fontSizeUpperLimit = 999;
    const fontSizeLowerLimit = 1;
    const newFontSize = fontSize + change;
    if(newFontSize >= fontSizeLowerLimit && newFontSize <= fontSizeUpperLimit){
      setFontSize(newFontSize);
      handleTextFontSize(fieldProps, newFontSize)
    }
  }

  return (
    <Fragment>
      <Modal
        open={dateModalState.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onSubmit={handleSubmit}
        onClose={handleClose}>
        <Box sx={style}>
          <form autoComplete="off" noValidate>
            <div className="text-nowrap text-center mb-3">
              {/* <h2 className="formHeading mb-0">{dateModalState.fieldName}</h2> */}
              <span className="text-wrap">Enter date below to fill field</span>
            </div>
            <div className="fieldSize">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: '100%' }}
                  
                  id= 'Text'
                  name= 'text'
                  label= 'Enter date'
                  value={values?.text ? (dayjs(values.text).isValid() ? dayjs(values.text) : "") : ""}
                  views={['month', 'day', 'year']}
                  onChange={(newValue) => {
                    if(newValue){
                      const formattedDate = newValue.isValid() ? newValue.format('MMM DD, YYYY') : '';
                      handleChange({
                        target: {
                          name: 'text',
                          value: formattedDate,
                        },
                      });
                    }
                    else{
                      if(newValue!==""){
                        handleChange({
                          target: {
                            name: 'text',
                            value: "",
                          },
                        });
                      }
                    }
                  }}

                  slotProps={{
                    textField: {
                      helperText: 'MM/DD/YYYY'
                    }
                  }}
                />
              </LocalizationProvider>
              <div className="errorfield">{errors.text && touched.text ? <span>{errors.text}</span> : null}</div>
            </div>

            <div className='d-flex justify-content-between fontDiv'>
              <span>Font Size: </span>
              <div>
                <span>{Math.floor(fontSize)}</span>
                <div className="btn-group" role="group" aria-label="Basic example">
                  <button type="button" className="btn" onClick={()=>handleFontSizeClick(-1)}>-</button>
                  <button type="button" className="btn" onClick={()=>handleFontSizeClick(+1)}>+</button>
                </div>
              </div>
            </div>

            <Button className="formButton" 
            type="submit"
            variant="contained" fullWidth>
              <span className="formButtonText">Save</span>
            </Button>
          </form>
        </Box>
      </Modal>
    </Fragment>
  );
};

EditDateModal.propTypes = {
  dateModalState: PropTypes.object,
  setDateModalState: PropTypes.func,
  updateFieldData: PropTypes.func
};

export default EditDateModal;

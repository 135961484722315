import {
  DOCUMENT_CLEAR,
  DOCUMENT_LOADING,
  GET_DOCUMENT_DETAIL_FAIL,
  GET_DOCUMENT_DETAIL_SUCCESS,
  GET_DOCUMENT_LIST_FAIL,
  GET_DOCUMENT_LIST_SUCCESS
} from '../action-types';

const initialState = {
  document_detail: null,
  document_list: [],
  loading: false
};

export const documentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DOCUMENT_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        document_list: payload.document_list,
        loading: false
      };
    case GET_DOCUMENT_LIST_FAIL:
      return {
        ...state,
        document_list: [],
        loading: false
      };
    case GET_DOCUMENT_DETAIL_SUCCESS:
      return {
        ...state,
        document_detail: payload.document_detail,
        loading: false
      };
    case GET_DOCUMENT_DETAIL_FAIL:
      return {
        ...state,
        document_detail: null,
        loading: false
      };
    case DOCUMENT_CLEAR:
      return initialState;
    default:
      return state;
  }
};

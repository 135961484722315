import React from 'react';
import logo from '../../assets/images/logo-white.svg';
import sessionExpiredImg from '../../assets/images/sessionExpired.svg';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router';

const SessionExpired = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <div className="">
        <Box className="nav-header d-flex justify-content-start px-3" style={{ position: 'relative' }}>
          <div className="logo">
            <a onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
              <img src={logo} />
            </a>
          </div>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <span className="title mx-3 font-weight-bold">eZy Sign</span>
          </div>
        </Box>
      </div>

      <div
        style={{
          flexGrow: '1',
          alignSelf: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem'
          }}>
          <img
            style={{
              width: '80%',
              maxWidth: '200px'
            }}
            src={sessionExpiredImg}
          />
        </div>
        <div className="d-flex justify-content-center w-100 align-items-center">
          <span
            style={{
              fontSize: '30px',
              fontWeight: 'bolder',
              textAlign: 'center'
            }}>
            Your session has expired
          </span>
        </div>
        <div className="d-flex justify-content-center w-100 align-items-center">
          <span style={{ fontSize: '12px', textAlign: 'center', marginTop: '1rem' }}>
            Please try opening document from URL you received via Email or SMS
          </span>
        </div>
      </div>
    </div>
  );
};

export default SessionExpired;

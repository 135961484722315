import * as yup from 'yup';
export let validateTextModalSchema = yup.object().shape({
  text: yup.string().required('Text is required')
});
export let validateDateModalSchema = yup.object().shape({
  text: yup
    .string()
    .required('Date is required')
    .test('isValidDate', 'Invalid date format', (value) => {
      // Check if the date string matches the format "MMM DD, YYYY"
      const regex = /^[a-zA-Z]{3}\s\d{1,2},\s\d{4}$/;
      return regex.test(value);
    })
});

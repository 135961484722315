/* eslint-disable */
import { Box, Modal, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import successImg from '../../assets/images/success.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Loader from './Loader';

const ProgressModal = ({ openProgressModal, setOpenProgressModal, processingString, uploadingAttachNo, totalAttach }) => {

  const handleClose = () => {
    setOpenProgressModal(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    minWidth: '280px',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    border: 'none',
    outline: 'none',
    boxShadow: 24,
    borderRadius: '8px',
    p: 3,
    padding: '0px'
  };

  return (
    <Fragment>
      <Modal
        open={openProgressModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={true}
        onClose={handleClose}>
        <Box sx={style}>
          <div className="progress-modal">
            {/* <div className='header-menu'>
              <div>
                <IconButton onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} style={{ width: '20px', height: '20px' }} />
                </IconButton>
              </div>
              <span>Uploading documents</span>
            </div>
            <hr/> */}

            <div className='progress-area'>
              <Loader color="primary" />
              <span className='text-center'>{processingString}
                {processingString==="Uploading attachment" ?
                  <span>
                    : {uploadingAttachNo}/{totalAttach}
                  </span>
                  : null
                }
              </span>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <span className='ProgressInfo text-center'>While this is in process, don't refresh the page</span>
            </div>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};

ProgressModal.propTypes = {
  openProgressModal: PropTypes.bool,
  setOpenProgressModal: PropTypes.func,
  processingString: PropTypes.string,
  uploadingAttachNo: PropTypes.number,
  totalAttach: PropTypes.number
};

export default ProgressModal;

/* eslint-disable */
import { StandardFonts, rgb } from 'pdf-lib';

export const isDataUriForImage = (dataUri) => {
  // Regular expression for a basic check of Data URL format for images
  const dataUriRegex = /^data:image\/(png|jpeg|jpg|gif);base64,/i;
  return dataUriRegex.test(dataUri);
};

export const drawTextToPdf = async (
  pdfDoc,
  pageNumber,
  fieldProperties,
  pdfCoordinates,
  fontSize,
  customLineHeight
) => {
  const pages = pdfDoc.getPages();
  const fontColor = rgb(0, 0, 0);

  const font = await pdfDoc.embedFont(StandardFonts.Courier);
  // fontSize = 50;
  // const font = await pdfDoc.embedFont(TimesRoman);
  // const lineHeight = font.heightAtSize(0.85 * fontSize); // for timesRoman font
  let firstLinePositionAdjustment = 0.75 * fontSize; //for courier font
  // const firstLinePositionAdjustment = 0.789 * fontSize;
  const new_y = pdfCoordinates?.y + pdfCoordinates?.height - firstLinePositionAdjustment;
  let currentY = new_y;

  const drawSingleLineText = (words) => {
    if (fieldProperties?.type === 'text' || fieldProperties?.type === 'date') {
      //for single line text
      //adjusting firstline position adjust to center of box for singleLine text box
      firstLinePositionAdjustment = 0.25 * fontSize;
      currentY = pdfCoordinates?.y + pdfCoordinates?.height / 2 - firstLinePositionAdjustment;
    }
    pages[pageNumber].drawText(words, {
      x: pdfCoordinates?.x,
      y: currentY,
      color: fontColor,
      size: fontSize,
      font
    });
    //resetting the values after the first line completed before the "\n"
    currentY -= fontSize * customLineHeight;
  };

  let arrayOfStrings = fieldProperties?.textValue.split('\n');
  if (fieldProperties?.type === 'multiline') {
    if (arrayOfStrings?.length > 0) {
      arrayOfStrings.forEach((singleLineString, index) => {
        drawSingleLineText(singleLineString);
      });
    }
  } else {
    // for single line / date text
    const singleLineString = arrayOfStrings[0];
    drawSingleLineText(singleLineString);
  }
};

export const drawImageToPdf = async (pdfDoc, pageNumber, fieldProperties, pdfCoordinates) => {
  const pages = pdfDoc.getPages();
  if (
    fieldProperties?.signatureUri instanceof HTMLImageElement &&
    fieldProperties?.signatureUri?.src &&
    isDataUriForImage(fieldProperties?.signatureUri?.src)
  ) {
    const signImage = await pdfDoc.embedPng(fieldProperties?.signatureUri?.src);
    //signature position coOrdinates on pdf page
    let position_x = pdfCoordinates?.x;
    let position_y = pdfCoordinates?.y;
    //signature container size on pdf page
    let signBoxWidth = pdfCoordinates?.width;
    let signBoxHeight = pdfCoordinates?.height;

    let hRatio = signBoxWidth / signImage.width;
    let vRatio = signBoxHeight / signImage.height;
    let ratio = Math.min(hRatio, vRatio);
    let centerShift_x = (signBoxWidth - signImage.width * ratio) / 2;
    let centerShift_y = (signBoxHeight - signImage.height * ratio) / 2;

    pages[pageNumber].drawImage(signImage, {
      x: position_x + centerShift_x,
      y: position_y + centerShift_y,
      width: signImage.width * ratio,
      height: signImage.height * ratio
    });
  }
};

export const scrollToPage = (pageNumber, smoothBehaviour = true) => {
  const pageElement = document.querySelector(`.pageContext[data-page-number="${pageNumber}"]`);
  if (pageNumber && pageElement) {
    pageElement.scrollIntoView({ behavior: smoothBehaviour ? 'smooth' : 'instant' });
  }
};

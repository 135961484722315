// import { LinearProgress } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { isObjectEmpty, truncateStringEnd } from '../../utils/utilityFunctions';
import toast from 'react-hot-toast';

const AttachmentDropdownMenu = ({ attachmentArray, modifiedAttachmentArray, setModifiedAttachmentArray }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [percentage, setPercentage] = useState(0);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const fileInputRefs = attachmentArray.map(() => useRef(null));

  const handleFileChange = (event) => {
    const file = event?.target?.files[0]; // Get the selected file
    const attachIndex = event?.target?.id;
    const maxFileSizeInBytes = 5 * 1024 * 1024; // for 5mb
    if (file && file.size <= maxFileSizeInBytes) {
      const newModifiedAttachmentArray = [...modifiedAttachmentArray];
      newModifiedAttachmentArray[attachIndex].selectedFileDetail = file;
      setModifiedAttachmentArray(newModifiedAttachmentArray);
    } else {
      toast.error('File size exceeds the limit of 5 MB.');
      // unattach the file if already attach, when new attachment is greater than maxFileSize
      const newModifiedAttachmentArray = [...modifiedAttachmentArray];
      newModifiedAttachmentArray[attachIndex].selectedFileDetail = {};
      setModifiedAttachmentArray(newModifiedAttachmentArray);
      event.target.value = null;
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    setPercentage(
      (modifiedAttachmentArray.filter((a) => !isObjectEmpty(a.selectedFileDetail)).length /
        modifiedAttachmentArray.length) *
        100
    );
  }, [modifiedAttachmentArray]);

  const styles = {
    paper: { borderRadius: '8px', padding: '2rem 1rem 0 1rem' },
    menuItem: { padding: 0 }
  };

  const handleInputDivClick = (index) => {
    // Trigger the click event on the hidden file input
    fileInputRefs[index].current.click();
  };

  return (
    <div style={{ zIndex: '13' }}>
      <div
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className="itemBtn btn theme-button-primary">
        {attachmentArray.length}
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
        transition
        disablePortal
        style={{
          width: '300px'
        }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'right top',
              caretColor: 'yellow'
            }}>
            <Paper elevation={3} style={styles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <h6 className="text-left">You need to attach {attachmentArray.length} files</h6>
                  <span className="fileSizeInfo">File size &lt; 5MB</span>
                  <LinearProgress variant="determinate" value={percentage} />

                  <MenuList
                    // autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}>
                    {attachmentArray.map((attachment, i) => (
                      <MenuItem
                        key={i}
                        // onClick={(event)=>handleFileUpload(event)}
                        className="d-flex"
                        // data-my-value={JSON.stringify(attachment)}
                        style={styles.menuItem}>
                        <input
                          type="file"
                          id={i}
                          style={{ display: 'none' }}
                          data-my-value={JSON.stringify(attachment)}
                          accept=".pdf,.bmp,.png,.jpg,.jpeg"
                          ref={fileInputRefs[i]}
                          onChange={(event) => handleFileChange(event)}
                        />

                        <div
                          className="d-flex w-100 align-items-center justify-content-between"
                          onClick={() => handleInputDivClick(i)}
                          style={{
                            padding: '4px 0px'
                          }}>
                          <div className="">
                            {attachment?.label ? truncateStringEnd(attachment?.label, 15) : 'Attachment Name'}
                            {!attachment.optional ? <span className="text-danger">&nbsp;∗</span> : null}
                          </div>
                          {/* {
                              modifiedAttachmentArray &&
                              modifiedAttachmentArray[i] &&
                              modifiedAttachmentArray[i]?.selectedFileDetail?.name ? (
                                <span className="mr-2 text-success" style={{ fontSize: '12px' }}>
                                  {truncateStringMiddle(modifiedAttachmentArray[i]?.selectedFileDetail?.name, 14)}
                                </span>
                              ) : (
                                <span className="mr-2 text-danger" style={{ fontSize: '12px' }}>
                                  {'No file chosen'}
                                </span>
                              )
                            } */}
                          <div
                            className="btn theme-button-attachment"
                            style={{
                              background:
                                modifiedAttachmentArray &&
                                modifiedAttachmentArray[i] &&
                                modifiedAttachmentArray[i]?.selectedFileDetail?.name
                                  ? '#008B00'
                                  : '#838383'
                            }}>
                            <label
                              className="mb-0"
                              style={{
                                cursor: 'pointer'
                              }}>
                              {modifiedAttachmentArray &&
                              modifiedAttachmentArray[i] &&
                              modifiedAttachmentArray[i]?.selectedFileDetail?.name
                                ? 'Edit'
                                : 'Attach'}
                            </label>
                            <FontAwesomeIcon icon={faPaperclip} className="ml-2" />
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

AttachmentDropdownMenu.propTypes = {
  attachmentArray: PropTypes.array,
  modifiedAttachmentArray: PropTypes.array,
  setModifiedAttachmentArray: PropTypes.func
};

export default AttachmentDropdownMenu;

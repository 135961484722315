import { useEffect } from 'react';

const helper_functions = {
  pixel_To_percent(x, y, CW, CH) {
    let Px = (x / CW) * 100;
    let Py = (y / CH) * 100;
    return {
      x: Px,
      y: Py
    };
  },
  percent_To_pixel(x, y, CW, CH) {
    let Px = (x / 100) * CW;
    let Py = (y / 100) * CH;
    return {
      x: Px,
      y: Py
    };
  },
  useDebounceEffect(fn, waitTime, deps) {
    useEffect(() => {
      const t = setTimeout(() => {
        fn.apply(undefined, deps);
      }, waitTime);

      return () => {
        clearTimeout(t);
      };
    }, deps);
  },
  degToRad(angle) {
    return (angle / 180) * Math.PI;
  },
  newPoints(centerx, centery, Px, Py, degree) {
    let x = centerx + (Px - centerx) * Math.cos(degree) - (Py - centery) * Math.sin(degree);
    let y = centery + (Px - centerx) * Math.sin(degree) + (Py - centery) * Math.cos(degree);
    return {
      centerx: centerx,
      centery: centery,
      oldx: Px,
      oldy: Py,
      X: x,
      Y: y
    };
  },
  alignment(
    property,
    imageProperties,
    shapeRef,
    degToRad,
    getCenterPoint,
    yCordsOfMainImage,
    xCordsOfMainImage,
    differenceFactor,
    canvasImageHeight,
    canvasImageWidth,
    setYCords,
    setXCords,
    newPoints
  ) {
    // eslint-disable-next-line no-prototype-builtins
    if (imageProperties.hasOwnProperty('alignEnable') && shapeRef.current) {
      let degree = imageProperties.deg;
      let deg = degToRad(degree);
      let xOld, yOld, centerx, centery, width, height;
      width = property
        ? shapeRef.current.width() * imageProperties.scaleX * differenceFactor
        : imageProperties.image.width * imageProperties.scaleX;
      height = property
        ? shapeRef.current.height() * imageProperties.scaleY * differenceFactor
        : imageProperties.image.height * imageProperties.scaleY;
      centerx = xCordsOfMainImage + width / 2;
      centery = yCordsOfMainImage + height / 2;

      switch (imageProperties.alignEnable) {
        case 'TopLeft':
          xOld = xCordsOfMainImage;
          yOld = yCordsOfMainImage;
          break;
        case 'TopRight':
          xOld = canvasImageWidth - width;
          yOld = yCordsOfMainImage;
          centerx = xOld + width / 2;
          centery = yOld + height / 2;
          break;
        case 'BottomLeft':
          xOld = xCordsOfMainImage;
          yOld = canvasImageHeight - height + yCordsOfMainImage;
          centerx = xOld + width / 2;
          centery = yOld + height / 2;
          break;
        case 'BottomRight':
          xOld = canvasImageWidth - width;
          yOld = canvasImageHeight - height + yCordsOfMainImage;
          centerx = xOld + width / 2;
          centery = yOld + height / 2;
          break;
        case 'TopCenter':
          xOld = canvasImageWidth / 2 - width / 2;
          yOld = yCordsOfMainImage;
          centerx = xOld + width / 2;
          centery = yOld + height / 2;
          break;
        case 'BottomCenter':
          xOld = canvasImageWidth / 2 - width / 2;
          yOld = canvasImageHeight - height + yCordsOfMainImage;
          centerx = xOld + width / 2;
          centery = yOld + height / 2;
          break;
        default:
          break;
      }

      let yOldPL = yOld + height;
      let xOldPR = xOld + width;
      let xOldPB = xOld + width;
      let yOldPB = yOld + height;
      let newPT = newPoints(centerx, centery, xOld, yOld, deg);
      let newPR = newPoints(centerx, centery, xOldPR, yOld, deg);
      let newPB = newPoints(centerx, centery, xOldPB, yOldPB, deg);
      let newPL = newPoints(centerx, centery, xOld, yOldPL, deg);

      let newX = 0;
      let newY = 0;

      switch (imageProperties.alignEnable) {
        case 'TopLeft':
          newX = Math.min(newPL.X, newPT.X, newPR.X, newPB.X) - xCordsOfMainImage;
          newY = Math.min(newPL.Y, newPT.Y, newPR.Y, newPB.Y) - yCordsOfMainImage;
          setXCords(-1 * newX + xCordsOfMainImage + getCenterPoint().x * imageProperties.scaleX);
          setYCords(-1 * newY + yCordsOfMainImage + getCenterPoint().y * imageProperties.scaleY);
          break;
        case 'TopRight':
          newX = Math.max(newPL.X, newPT.X, newPR.X, newPB.X) - canvasImageWidth - xCordsOfMainImage;
          newY = Math.min(newPL.Y, newPT.Y, newPR.Y, newPB.Y) - yCordsOfMainImage;
          setXCords(-1 * newX + xOld + getCenterPoint().x * imageProperties.scaleX);
          setYCords(-1 * newY + yCordsOfMainImage + getCenterPoint().y * imageProperties.scaleY);
          break;

        case 'BottomLeft':
          newX = Math.min(newPL.X, newPT.X, newPR.X, newPB.X) - xCordsOfMainImage;
          newY = Math.max(newPL.Y, newPT.Y, newPR.Y, newPB.Y) - canvasImageHeight - yCordsOfMainImage;
          setXCords(-1 * newX + xCordsOfMainImage + getCenterPoint().x * imageProperties.scaleX);
          setYCords(-1 * newY + yOld + getCenterPoint().y * imageProperties.scaleY);
          break;

        case 'BottomRight':
          newX = Math.max(newPL.X, newPT.X, newPR.X, newPB.X) - canvasImageWidth - xCordsOfMainImage;
          newY = Math.max(newPL.Y, newPT.Y, newPR.Y, newPB.Y) - canvasImageHeight - yCordsOfMainImage;
          setXCords(-1 * newX + xOld + getCenterPoint().x * imageProperties.scaleX);
          setYCords(-1 * newY + yOld + getCenterPoint().y * imageProperties.scaleY);
          break;
        case 'TopCenter':
          newY = Math.min(newPL.Y, newPT.Y, newPR.Y, newPB.Y) - yCordsOfMainImage;
          setXCords(xOld + xCordsOfMainImage + getCenterPoint().x * imageProperties.scaleX);
          setYCords(-1 * newY + yOld + getCenterPoint().y * imageProperties.scaleY);

          break;
        case 'BottomCenter':
          newY = Math.max(newPL.Y, newPT.Y, newPR.Y, newPB.Y) - canvasImageHeight - yCordsOfMainImage;
          setXCords(xOld + xCordsOfMainImage + getCenterPoint().x * imageProperties.scaleX);
          setYCords(-1 * newY + yOld + getCenterPoint().y * imageProperties.scaleY);
          break;
        case 'Center':
          setXCords(canvasImageWidth / 2 + xCordsOfMainImage - width / 2 + getCenterPoint().x * imageProperties.scaleX);
          setYCords(
            canvasImageHeight / 2 + yCordsOfMainImage - height / 2 + getCenterPoint().y * imageProperties.scaleY
          );
          break;
      }
    }
  },
  dataURLtoFile(dataurl, filename) {
    if (dataurl) {
      var arr = dataurl.split(','),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename);
    }
  }
};

export default helper_functions;

import { Box, Modal, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import successImg from '../../assets/images/success.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const SuccessModal = ({ openSuccessModal, setOpenSuccessModal }) => {
  const handleClose = () => {
    setOpenSuccessModal(false);
    localStorage.setItem('just_signed', true);
    window.location.reload();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    minWidth: '280px',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    border: 'none',
    outline: 'none',
    boxShadow: 24,
    borderRadius: '8px',
    p: 3,
    padding: '0px'
  };

  return (
    <Fragment>
      <Modal
        open={openSuccessModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}>
        <Box sx={style}>
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 10
            }}>
            <IconButton onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} style={{ width: '20px', height: '20px' }} />
            </IconButton>
          </div>

          <div className="success-modal">
            <div className="d-flex justify-content-center align-items-center mb-4">
              <img src={successImg} />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span>You have successfully signed document</span>
            </div>

            <div className="appAdvertisement">
              <span>Get the eZy Sign app!</span>
              <a
                href="https://apps.apple.com/us/app/scan-esign-fill-docs-lite/id971648827"
                target="_blank"
                rel="noreferrer"
                aria-labelledby="redirect to app store">
                <img src="/mono.png" />
              </a>
            </div>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};

SuccessModal.propTypes = {
  openSuccessModal: PropTypes.bool,
  setOpenSuccessModal: PropTypes.func
};

export default SuccessModal;

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import arrowDownBlue from '../../assets/images/arrow-down-blue.svg';
import arrowDownGrey from '../../assets/images/arrow-down-grey.svg';
import arrowUpBlue from '../../assets/images/arrow-up-blue.svg';
import arrowUpGrey from '../../assets/images/arrow-up-grey.svg';
import { scrollToPage } from '../../utils/pdfRelatedFunctions';

const PageNumber2 = ({ totalPages, currentPage, setCurrentPage }) => {
  // const pages = document.querySelectorAll(`.react-pdf__Page`);
  const scrollElement = document.querySelector('.scrollElement');
  const pages = document.getElementsByClassName('pageContext');

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && userStartedScrolling) {
        const pageNumber = parseInt(entry.target.dataset.pageNumber, 10);
        // console.log('bgtrvfdgrevcvcx ', pageNumber);
        setCurrentPage(pageNumber);
      }
    });
  };

  let userStartedScrolling = false;

  const handleScroll = () => {
    userStartedScrolling = true;
  };

  useEffect(() => {
    // Convert HTMLCollection to an array
    const pagesArray = Array.from(pages);

    // console.log('Pages:', pagesArray);

    if (!scrollElement || !pagesArray) {
      // console.log('scrollElement not found');
      return;
    }

    const observer = new IntersectionObserver(handleIntersection, {
      root: scrollElement,
      threshold: 0.5 // Adjust as needed
    });

    pagesArray.forEach((page) => {
      observer.observe(page);
    });

    scrollElement.addEventListener('scroll', handleScroll);

    return () => {
      observer.disconnect();
      scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, [scrollElement, totalPages]);

  //   useEffect(() => {
  //     const handleScroll = () => {
  //       const totalPagesScrollHeight = scrollElement.scrollHeight;
  //       const pageScrollHeight = totalPagesScrollHeight / totalPages;
  //       const currentScroll = scrollElement.scrollTop;
  //       //   console.log('Total height', totalPagesScrollHeight);
  //       //   console.log('Per page height', pageScrollHeight);
  //       //   console.log('Current scroll', currentScroll);

  //       const calculatedPage = currentScroll === 0 ? 1 : Math.ceil(currentScroll / pageScrollHeight);
  //       console.log(calculatedPage);
  //       setCurrentPage(calculatedPage);
  //       if (calculatedPage !== currentPage) {
  //         console.log('Page changed', { totalPagesScrollHeight, pageScrollHeight, currentScroll });
  //       }
  //     };
  //     scrollElement.addEventListener('scroll', handleScroll);
  //     return () => {
  //       scrollElement.removeEventListener('scroll', handleScroll);
  //     };
  //   }, []);

  const scrollUp = () => {
    if (currentPage > 1) {
      const newCurrentPage = currentPage - 1;
      setCurrentPage(newCurrentPage);
      scrollToPage(newCurrentPage);
      // const pageElement = document.querySelector(`.react-pdf__Page[data-page-number="${newCurrentPage}"]`);
      // if (pageElement) {
      //   pageElement.scrollIntoView({ behavior: 'smooth' });
      // }
    }
  };

  const scrollDown = () => {
    if (currentPage < totalPages) {
      const newCurrentPage = currentPage + 1;
      setCurrentPage(newCurrentPage);
      scrollToPage(newCurrentPage);
      // const pageElement = document.querySelector(`.react-pdf__Page[data-page-number="${newCurrentPage}"]`);
      // if (pageElement) {
      //   pageElement.scrollIntoView({ behavior: 'smooth' });
      // }
    }
  };

  return (
    <div className="pagination d-flex justify-content-center">
      <img className="mr-2" src={currentPage > 1 ? arrowUpBlue : arrowUpGrey} onClick={() => scrollUp()} />
      <img
        className="mr-2"
        src={currentPage < totalPages ? arrowDownBlue : arrowDownGrey}
        onClick={() => scrollDown()}
      />
      <span>{`${currentPage} / ${totalPages}`}</span>
    </div>
  );
};
PageNumber2.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func
};

export default PageNumber2;

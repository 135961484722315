/* prettier-ignore */
/* eslint-disable */

import jQuery from 'jquery';
const jquery = jQuery.noConflict();

!(function(jquery) {
  "use strict";

  // Back to top button
  jquery(window).scroll(function() {
    if (jquery(this).scrollTop() > 100) {
      jquery('.back-to-top').fadeIn('slow');
    } else {
      jquery('.back-to-top').fadeOut('slow');
    }
  }); 

  
  jquery(window).on('load', function() {
    jquery(document).on('click', '.mobile-nav-toggle', function (e) {
      jquery('div#root').toggleClass('mobile-nav-active');
      jquery('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
      jquery('.mobile-nav-overly').toggle();
    });

    jquery(document).on('click', '.mobile-nav .drop-down > a', function (e) {
      e.preventDefault();
      jquery(this).next().slideToggle(300);
      jquery(this).parent().toggleClass('active');
    });

    jquery(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        e.preventDefault();
        var target = jquery(this.hash);
        if (target.length) {
          var scrollto = target.offset().top;
          jquery('html, div#root').animate(
            {
              scrollTop: scrollto
            },
            1500,
            'easeInOutExpo'
          );

          if (jquery(this).parents('.nav-menu, .mobile-nav').length) {
            jquery('.nav-menu .active, .mobile-nav .active').removeClass('active');
            jquery(this).closest('li').addClass('active');
          }

          if (jquery('div#root').hasClass('mobile-nav-active')) {
            jquery('div#root').removeClass('mobile-nav-active');
            jquery('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            jquery('.mobile-nav-overly').fadeOut();
          }
          return false;
        }
      }
    });
  });
  // Initi AOS
  // AOS.init({
  //   duration: 800,
  //   easing: "ease-in-out"
  // });
})(jQuery);